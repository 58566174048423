import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex cursor-pointer items-center px-6 py-4 transition-colors", [
            _ctx.selected
                ? 'bg-dark-blue text-white'
                : 'text-gray-400 hover:bg-gray-200 hover:text-gray-500',
            { 'pointer-events-none opacity-20': _ctx.disabled, 'hidden': _ctx.hidden }
        ]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.selected)
      ? (_openBlock(), _createBlock(_component_biz_icon, {
          key: 0,
          name: "close",
          class: "mr-4 h-2.5 w-2.5 fill-current text-white"
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
    ])
  ], 2))
}