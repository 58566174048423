export default {
    mounted(element: HTMLElement) {
        // get cta
        const targetElement = document.querySelector('.tr-cta-section');

        if (targetElement) {
            let targetId = targetElement.getAttribute('id');

            // add id to CTA if it doesn't have one
            if (!targetId) {
                targetId = 'cta';
                targetElement.setAttribute('id', targetId);
            }

            // create a link element
            const linkElement = document.createElement('a');
            linkElement.href = `#${targetId}`;
            // get current attributes
            Array.from(element.attributes).forEach(attr => {
                linkElement.setAttribute(attr.nodeName, attr.nodeValue);
            });
            linkElement.innerHTML = element.innerHTML;

            // replace the element with the link element
            element.parentNode.replaceChild(linkElement, element);
        } else {
            element.classList.add('hidden');
        }
    }
};
