
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        id: String,
        hero: {
            type: Boolean,
            default: false,
            name: String,
            index: Number,
            filterKey: String
        }
    },
    data() {
        return {};
    },
    inject: ['registerProductFilter'],
    mounted() {
        this.registerProductFilter(this);
    },
    methods: {},
    computed: {},
    watch: {}
});
