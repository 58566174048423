import { DirectiveBinding } from 'vue';

export default {
    created(el: HTMLElement, binding: DirectiveBinding) {
        // add or remove bliding value
        document.addEventListener('scroll', () => {
            // add class to element if scrolled
            if (document.documentElement.scrollTop > 0) {
                el.classList.add(binding.value);
            } else {
                el.classList.remove(binding.value);
            }
        });
    }
};
