
import { defineComponent } from 'vue';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export default defineComponent({
    props: {
        items: {
            type: Array as () => { anchor: string; title: string }[]
        }
    },
    data() {
        return {
            active: '',
            contrast: false
        };
    },
    mounted() {
        gsap.registerPlugin(ScrollToPlugin);
        // get all section to get anchor id
        const sections = document.querySelectorAll('section');

        // on scroll get current anchor id, and see if it's contrast
        window.onscroll = () => {
            const windowHeight = window.innerHeight;
            const halfWindowHeight = windowHeight / 2;
            sections.forEach(section => {
                // get section values
                const positions = section.getBoundingClientRect();
                const sectionStart = positions.top;
                const sectionEnd = positions.bottom;

                // if CTA get contrast part
                let partPositions = null;
                if (
                    section.classList.contains('cta-contrast-regular') ||
                    section.classList.contains('cta-contrast-hero')
                ) {
                    const firstDiv = section.querySelector('div');
                    const childIndex = section.classList.contains('cta-contrast-regular') ? 0 : 1;
                    if (firstDiv && firstDiv.children[childIndex]) {
                        partPositions = firstDiv.children[childIndex].getBoundingClientRect();
                    }
                }
                const partStart = partPositions ? partPositions.top : null;
                const partEnd = partPositions ? partPositions.bottom : null;

                // if CTA contrast part
                if (
                    partStart &&
                    partEnd &&
                    partStart <= halfWindowHeight &&
                    partEnd > halfWindowHeight
                ) {
                    this.contrast = true;
                }
                // only change active when it's in the middle of the screen and it isn't sticky
                else if (
                    sectionStart <= halfWindowHeight &&
                    sectionEnd > halfWindowHeight &&
                    (this.active !== section.getAttribute('id') ||
                        (partStart != null && this.contrast)) &&
                    !section.classList.contains('sticky')
                ) {
                    this.active = section.getAttribute('id');
                    // if section have class text-white we will have content navigation in white
                    if (section.classList.contains('text-white')) {
                        this.contrast = true;
                    } else {
                        this.contrast = false;
                    }
                }
            });
        };
    },
    methods: {
        scrollTo(element: HTMLElement) {
            const positions = element.getBoundingClientRect();
            const sectionPositionStart = positions.top + window.scrollY;

            const speed = 6000;
            const duration = sectionPositionStart / speed;

            if (sectionPositionStart > 20) {
                gsap.to(window, {
                    duration: duration,
                    scrollTo: element,
                    onComplete: () => {
                        const newPositions = element.getBoundingClientRect();
                        if (positions.top - newPositions.top < 100) {
                            this.scrollTo(element);
                        }
                    }
                });
            }
        },
        scrollToAnchor(anchor: string) {
            const targetElement = document.getElementById(anchor);
            this.scrollTo(targetElement);
        }
    }
});
