
import { defineComponent, h } from 'vue';
import { getTwConfig } from '@/utils';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    props: {
        title: String,
        link: String,
        target: String,
        icon: String,
        items: Array,
        transparent: Boolean
    },
    data() {
        return {
            open: false,
            twConfig: getTwConfig()
        };
    },
    render() {
        const icon = this.icon
            ? h(BizIcon, {
                  name: this.icon,
                  class: 'mt-1 hidden h-4.5 w-4.5 shrink-0 fill-current lg:block'
              })
            : null;
        const chevron = h(BizIcon, {
            name: 'chevron-right',
            class: 'ml-2 rotate-90 h-3 w-3 shrink-0 fill-current lg:hidden'
        });
        const hover = this.items.length
            ? h(
                  'div',
                  {
                      class: [
                          'top-full left-0 z-40 max-h-0 w-full overflow-hidden transition-all duration-1000 lg:group-hover:max-h-96 lg:absolute lg:w-auto lg:shadow-lg',
                          { 'max-h-screen': this.open }
                      ]
                  },
                  [
                      h(
                          'ul',
                          {
                              class: 'max-h-max border-gray-500 bg-white py-6 pl-2 pr-4 text-gray-500 lg:border-t-2 lg:px-8'
                          },
                          this.items.map(item => {
                              if (item.link) {
                                  return h(
                                      'li',
                                      { class: 'whitespace-nowrap py-2 text-sm font-light' },
                                      [
                                          h(
                                              'a',
                                              {
                                                  href: item.link,
                                                  target: item.target,
                                                  class: 'hover:text-black'
                                              },
                                              item.title
                                          )
                                      ]
                                  );
                              }
                              return null;
                          })
                      )
                  ]
              )
            : h('span', {
                  class: 'absolute bottom-0 left-0 w-full scale-0 transition-transform duration-300 lg:border-b-2 lg:border-current lg:group-hover:scale-100'
              });
        const title = h(
            'span',
            {
                class: 'inline-block overflow-hidden border-l border-transparent text-sm shrink-0 transition-all duration-1000 lg:group-hover:ml-2 lg:group-hover:max-w-3xl lg:group-hover:border-current lg:group-hover:pl-2 lg:max-w-0 lg:whitespace-nowrap'
            },
            this.title
        );
        if (this.link) {
            return h(
                'a',
                {
                    href: this.link,
                    target: this.target,
                    class: `group flex h-full items-center relative py-1.5 ${
                        this.transparent ? 'text-white' : 'text-gray-400 hover:text-gray-500'
                    }`
                },
                [icon, title, this.items.length ? chevron : null, hover]
            );
        } else {
            return h(
                'div',
                {
                    class: `group relative flex h-full flex-col items-start py-1.5 lg:flex-row lg:items-center cursor-default ${
                        this.transparent ? 'text-white' : 'text-gray-400 hover:text-gray-500'
                    }`,
                    onClick: this.toggle
                },
                [
                    h('span', { class: 'flex items-center' }, [
                        icon,
                        title,
                        this.items.length ? chevron : null
                    ]),
                    hover
                ]
            );
        }
    },
    methods: {
        toggle() {
            if (this.items.length && window.innerWidth < parseInt(this.twConfig.theme.screens.lg)) {
                this.open = !this.open;
            }
        }
    }
});
