import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sticky top-36 z-30 hidden self-start xl:block 2xl:ml-24" }
const _hoisted_2 = { class: "ml-4 h-[250px] w-[250px] -rotate-90 transform xl:text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["xl:border-b-2", 
                        _ctx.inactive ? 'inactive xl:border-gray-400/10' : 'active xl:border-gray-400/30'
                    ])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["ml-auto -mb-0.5 px-2 font-quote text-6xl-quote-long xl:w-fit xl:border-b-2", _ctx.contrast ? 'xl:border-white' : 'xl:border-gray-500']),
            style: _normalizeStyle('margin-right:' + _ctx.stickyPosition)
          }, _toDisplayString(_ctx.stickyText), 7)
        ], 2)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}