
import { defineComponent } from 'vue';
import { filterItems } from '@/utils';

export default defineComponent({
    props: {},
    data() {
        return {
            items: []
        };
    },
    provide() {
        return {
            registerSiteNavItem: this.register,
            clickSiteNavItem: this.onClick
        };
    },
    methods: {
        register(item) {
            this.items.push(item);
            if (
                this.$slots.default().filter(filterItems).length === this.items.length &&
                this.items.length > 0
            ) {
                this.items[0].open = true;
            }
        },
        onClick(item) {
            this.items.forEach(i => {
                i.open = false;
            });
            item.open = true;
        }
    },
    computed: {},
    watch: {}
});
