
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        id: String,
        title: String
    },
    data() {
        return {
            active: false,
            readMore: null
        };
    },
    provide() {
        return {
            registerReadMore: this.registerReadMore
        };
    },
    inject: ['registerProductVariant'],
    mounted() {
        this.registerProductVariant(this);
    },
    methods: {
        registerReadMore(rm) {
            this.readMore = rm;
        }
    },
    watch: {
        active() {
            if (!this.active) {
                this.readMore.collapsed = this.readMore.exceedsHeight;
            }
        }
    }
});
