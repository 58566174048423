
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'HighlightTab',
    data() {
        return {
            active: false
        };
    },
    props: {
        title: String
    },
    inject: ['registerTab'],
    mounted() {
        this.registerTab(this);
    }
});
