
import { defineComponent } from 'vue';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    components: { BizIcon },
    props: {
        title: String,
        value: {
            type: String,
            default: ''
        },
        active: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hidden: {
            type: Boolean,
            default: false
        },
        cancelable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            contrast: false,
            selected: false
        };
    },
    inject: ['registerDropdownItem', 'dropdownItemClicked'],
    mounted() {
        this.registerDropdownItem(this);
    },
    methods: {
        onClick() {
            this.dropdownItemClicked(this);
        }
    }
});
