
import { defineComponent } from 'vue';
import { getTwConfig } from '@/utils';
import events from '@/events';

export default defineComponent({
    props: {
        maxHeight: {
            type: Number,
            required: true
        },
        gradientHeight: {
            type: Number,
            default: 64
        },
        editMode: {
            type: Boolean,
            default: false
        },
        showMoreLabel: {
            type: String,
            required: true
        },
        showLessLabel: {
            type: String,
            required: true
        },
        mobile: {
            type: Boolean,
            default: true
        },
        desktop: {
            type: Boolean,
            default: false
        },
        contrast: {
            type: Boolean,
            default: false
        }
    },
    inject: {
        registerReadMore: {
            default: () => () => undefined
        }
    },
    data() {
        return {
            collapsed: false,
            contentHeight: '',
            exceedsHeight: false,
            showMoreBtnClick: false,
            twConfig: getTwConfig()
        };
    },
    created() {
        window.addEventListener('resize', this.checkHeight);
        events.$on('height-changed', this.checkHeight);
    },
    mounted() {
        this.registerReadMore(this);
        if (!this.editMode) {
            const resizeObserver = new ResizeObserver(() => {
                this.checkHeight();
            });
            resizeObserver.observe(this.$refs.content);

            this.$nextTick(() => {
                this.contentHeight = this.$refs.content.scrollHeight;
                this.exceedsHeight = this.contentHeight > this.maxHeight;
                this.collapsed = this.exceedsHeight;
                this.checkHeight();
            });
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkHeight);
    },
    methods: {
        toggleCollapsed() {
            this.collapsed = !this.collapsed;
            this.showMoreBtnClick = true;
        },
        checkHeight() {
            if (!this.mobile && window.innerWidth < this.breakpointSize('lg')) {
                this.contentHeight = '';
                this.collapsed = this.exceedsHeight = false;
                return;
            }
            if (!this.desktop && window.innerWidth >= this.breakpointSize('lg')) {
                this.contentHeight = '';
                this.collapsed = this.exceedsHeight = false;
                return;
            }
            this.contentHeight = this.$refs.content.scrollHeight;
            this.exceedsHeight = this.contentHeight > this.maxHeight;
            if (!this.showMoreBtnClick) {
                this.collapsed = this.exceedsHeight;
                this.showMoreBtnClick = false;
            }
        },
        breakpointSize(name: string): number {
            if (Object.prototype.hasOwnProperty.call(this.twConfig.theme.screens, name)) {
                return parseInt(this.twConfig.theme.screens[name]);
            }
            return -1;
        }
    },
    computed: {
        height() {
            if (this.collapsed) {
                return {
                    maxHeight: `${this.maxHeight}px`
                };
            }
            return this.contentHeight ? { maxHeight: `${this.contentHeight}px` } : {};
        },
        gradHeight() {
            return {
                height: `${this.gradientHeight}px`,
                marginTop: `${-this.gradientHeight + 14}px`
            };
        }
    }
});
