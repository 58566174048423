
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        name: String,
        source: {
            type: String,
            default: '/img/sprite.svg'
        }
    },
    computed: {
        sprite() {
            return (this.$prototype ? '' : '/.resources/bizerba/webresources') + this.source;
        }
    }
});
