/* eslint-disable dot-notation */
import { createApp } from 'vue';
import Bizerba from './bizerba';
import Docs from 'lz-docs';
import { setCookie } from '@/utils';

const app = createApp({
    data() {
        return {
            hash: ''
        };
    },
    mounted() {
        window.addEventListener('hashchange', this.onHashchange);
        this.onHashchange();
        this.saveCampaignParameter();
    },
    methods: {
        onHashchange() {
            // manually scroll to anchor links, since hash can also contain other state info
            if (location.hash && location.hash.length) {
                location.href = location.hash;
                const hash = location.hash
                    .replace(/^#/, '')
                    .split('&')
                    .filter(x => !x.includes(':'));
                if (hash.length > 0 && hash[0]) {
                    const el = document.getElementById(hash[0]);
                    if (el) {
                        setTimeout(() => {
                            this.$smoothScroll({
                                scrollTo: el,
                                updateHistory: false,
                                hash: location.hash
                            });
                        }, 500);
                    }
                }
            }
        },
        saveCampaignParameter() {
            const urlParams = new URLSearchParams(window.location.search);
            const campaign = urlParams.get('utm_campaign');
            if (campaign) {
                setCookie('utm_campaign', campaign, 1);
            }
        }
    }
});

/* Plugins */
app.use(Bizerba);
app.use(Docs);

// IMPORTANT FOR GREENBARS
app.config.compilerOptions.comments = true;

/* mount app AFTER all global components are registered */
app.mount('#app');

declare module 'vue' {
    interface ComponentCustomProperties {
        $contextPath: string;
        $resourcePath: string;
        $siteName: string;
        $lang: string;
        $prototype: string;
        $editMode: boolean;
    }
}
