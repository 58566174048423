
import { defineComponent } from 'vue';
import { addToState, filterItems, removeFromState } from '@/utils';
import OverviewFilter from '@/components/overview/OverviewFilter.vue';
import OverviewItem from '@/components/overview/OverviewItem.vue';

export default defineComponent({
    props: {
        filterClasses: String,
        noResultsClasses: String
    },
    data() {
        return {
            items: [],
            filters: [],
            selectedCategories: [],
            searchValue: String,
            applyLimit: true,
            limited: false
        };
    },
    mounted() {
        window.addEventListener('hashchange', this.updateHash);
    },
    beforeUnmount() {
        window.removeEventListener('hashchange', this.updateHash);
    },
    provide() {
        return {
            registerOverviewItem: this.registerItem,
            registerOverviewFilter: this.registerFilter,
            toggleOverviewFilter: this.toggleCategory,
            toggleOverviewSearch: this.toogleSearch,
            overviewItems: this.items
        };
    },
    methods: {
        registerItem(item: InstanceType<typeof OverviewItem>) {
            this.items.push(item);
            if (this.$slots.default().filter(filterItems).length === this.items.length) {
                this.updateHash();
            }
        },
        registerFilter(filter: InstanceType<typeof OverviewFilter>) {
            this.filters.push(filter);
        },
        filter() {
            this.items.forEach((item: InstanceType<typeof OverviewItem>) => {
                item.checkFilters(this.selectedCategories, this.searchValue);
            });
            this.items
                .filter(x => x.show)
                .forEach((x, i) => {
                    x.index = i;
                    x.total = this.total;
                });
        },
        toggleCategory(
            categoryId: string,
            removeId = '',
            selection: { filter: string; category: string } = null
        ) {
            if (this.selectedCategories.includes(removeId)) {
                removeFromState(selection.filter);
                this.selectedCategories.splice(this.selectedCategories.indexOf(removeId), 1);
            }
            if (this.selectedCategories.includes(categoryId)) {
                removeFromState(selection.filter);
                this.selectedCategories.splice(this.selectedCategories.indexOf(categoryId), 1);
            } else if (categoryId !== removeId) {
                addToState(selection.filter, selection.category);
                this.selectedCategories.push(categoryId);
            }
            this.filter();
        },
        toogleSearch(searchInput: string) {
            this.searchValue = searchInput;
            this.filter();
        },
        updateHash() {
            const hash = location.hash.replace(/^#/, '');
            const filters = hash
                .split('&')
                .filter(x => x.includes(':'))
                .map(x => x.split(':'))
                .filter(x => x.length === 2);
            filters.forEach(f => {
                const filterCategory = this.filters.find(x => x.filterKey === f[0]);
                if (filterCategory) {
                    filterCategory.toggleCategoryByKey(f[1]);
                }
            });
            this.filter();
        }
    },
    computed: {
        total(): number {
            return this.items.filter(x => x.show).length;
        }
    }
});
