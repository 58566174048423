
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import BizIcon from '@/components/base/BizIcon.vue';
import events from '@/events';

export default defineComponent({
    components: { BizIcon, CollapseTransition },
    props: {
        title: String,
        group: String,
        open: {
            type: Boolean,
            default: false
        },
        boldBorder: {
            type: Boolean,
            default: false
        },
        // plusIcon make icon on top not centered
        plusIcon: {
            type: Boolean,
            default: false
        },
        centered: {
            type: Boolean,
            default: false
        },
        contrast: {
            type: Boolean,
            default: false
        },
        bigIcon: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: this.open
        };
    },
    created() {
        events.$on('accordion-open', this.onAccordionOpened);
    },
    beforeUnmount() {
        events.$off('accordion-open', this.onAccordionOpened);
    },
    methods: {
        onAccordionOpened(group) {
            if (!this.group || this.group === '') {
                return;
            }
            if (this.group === group) {
                this.visible = false;
            }
        },
        toggle() {
            if (!this.visible) {
                events.$emit('accordion-open', this.group);
            }
            this.visible = !this.visible;
            this.$emit('toggle', this.visible);
        }
    },
    computed: {
        iconClass() {
            return {
                'h-4 w-4': this.bigIcon,
                'h-3 w-3': !this.bigIcon,
                'fill-current': !this.plusIcon,
                'mt-8 rotate-45 fill-gray-400 lg:mt-11':
                    !this.visible && this.plusIcon && !this.contrast,
                'mt-8 rotate-0 fill-gray-500 lg:mt-11':
                    this.visible && this.plusIcon && !this.contrast,
                'mt-8 rotate-45 fill-white lg:mt-11':
                    !this.visible && this.plusIcon && this.contrast && !this.centered,
                'rotate-45 fill-white':
                    !this.visible && this.plusIcon && this.contrast && this.centered,
                'mt-8 rotate-0 fill-white lg:mt-11':
                    this.visible && this.plusIcon && this.contrast && !this.centered,
                'rotate-0 fill-white':
                    this.visible && this.plusIcon && this.contrast && this.centered,
                '-rotate-90': this.visible && !this.plusIcon,
                'rotate-90': !this.visible && !this.plusIcon
            };
        }
    }
});
