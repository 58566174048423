
import { defineComponent } from 'vue';
import events from '@/events';

export default defineComponent({
    name: 'HighlightTabs',
    data() {
        return {
            tabs: []
        };
    },
    props: {
        contrast: {
            type: Boolean,
            default: false
        }
    },
    provide() {
        return {
            registerTab: this.registerTab
        };
    },
    mounted() {
        if (this.tabs.length) {
            this.tabs[0].active = true;
        }
    },
    methods: {
        registerTab(tab) {
            this.tabs.push(tab);
        },
        activate(tab) {
            if (tab.active) {
                return;
            } else if (tab.index === 0) {
                tab.active = true;
            }

            this.tabs.forEach(t => {
                t.active = false;
            });
            tab.active = true;
            this.$nextTick(() => {
                events.$emit('height-changed', null);
            });
        }
    }
});
