import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative", { 'pointer-events-none': _ctx.disabled }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["border-b lg:mx-0 lg:px-0", [
                _ctx.plusIcon ? 'py-4' : 'py-2',
                _ctx.contrast ? 'border-gray-100/30' : 'border-gray-400/30'
            ]])
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(["flex w-full justify-between", { 'opacity-50': _ctx.disabled, 'items-center': !_ctx.plusIcon || _ctx.centered }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
      }, [
        _renderSlot(_ctx.$slots, "title", { open: _ctx.visible }, () => [
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
        ]),
        _createVNode(_component_biz_icon, {
          name: _ctx.plusIcon ? 'close' : 'chevron-right',
          class: _normalizeClass(["shrink-0 transition-transform", _ctx.iconClass])
        }, null, 8, ["name", "class"])
      ], 2),
      _withDirectives(_createVNode(_component_collapse_transition, null, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        _: 3
      }, 512), [
        [_vShow, _ctx.visible]
      ])
    ], 2),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["absolute left-0 bottom-0 h-0.5 w-full transition-colors duration-300", [
                {
                    'bg-gray-500': _ctx.visible && !_ctx.contrast,
                    'bg-transparent': _ctx.visible && _ctx.contrast,
                    'bg-transparent': !_ctx.visible
                }
            ]])
    }, null, 2), [
      [_vShow, _ctx.boldBorder]
    ])
  ], 2))
}