import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative h-0 w-full pt-1/1 lg:pt-16/9",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.active = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.active = false))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute top-0 left-0 h-full w-full", [_ctx.active ? 'pointer-events-auto' : 'pointer-events-none']])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 32))
}