/*
 *
 * Directive to animate an HTML element when it is in view
 *
 * Usage:
 *
 * 1. set 'v-animate' directive on element, and it will take the all default values
 *    and show an 'swipe-left' animation
 *
 * 2. to configure another animation style, set a name to the 'v-animate' directive
 *    like this 'v-animate:swipe-right' or 'v-animate:slide'
 *
 * 3. to configure the transform values, set the values to the 'v-animate' directive
 *    like this 'v-animate:slide="{ delay: 0, duration: 1000, translateY: '-100px', threshold: 0.05}'
 *
 * 4. to configure the animation on specific device (desktop or mobile),
 *    set a modifier to the 'v-animate' directive
 *    like this 'v-animate:slide.desktop.mobile'
 *
 */

import { getTwConfig } from '@/utils';
import { DirectiveBinding } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import MatchMedia = gsap.MatchMedia;

export default {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        // eslint-disable-next-line dot-notation
        if (!window['editMode']) {
            const twConfig = getTwConfig();
            const _mobile = binding.modifiers.mobile;
            const _desktop = binding.modifiers.desktop;
            const _onload = binding.modifiers.load;
            const _name = binding.arg ? binding.arg : 'swipe-left';
            let _delay = 0;
            let _threshold = 0.005;
            let _duration = 1000;
            let _translateY = '-100px';

            if (binding.value) {
                _delay = binding.value.delay ? binding.value.delay : _delay;
                _threshold = binding.value.threshold ? binding.value.threshold : _threshold;
                _duration = binding.value.duration ? binding.value.duration : _duration;
                _translateY = binding.value.translateY ? binding.value.translateY : _translateY;
            }

            if (
                (!_desktop && !_mobile) ||
                (_mobile && window.innerWidth < breakpointSize('lg')) ||
                (_desktop && window.innerWidth >= breakpointSize('lg'))
            ) {
                setupAnimation();
            }

            function setupAnimation() {
                if (_name === 'slide') {
                    el.style.transition = `transform ${_duration}ms ease-in-out ${_delay}ms`;
                    el.style.transform = `translateY(${_translateY})`;
                } else if (_name === 'pan') {
                    const scrollParent = binding.value
                        ? binding.value.parent || parentSection(el)
                        : parentSection(el);
                    gsap.registerPlugin(ScrollTrigger);
                    const mm: MatchMedia = gsap.matchMedia();
                    const breakPoint = breakpointSize('lg');
                    mm.add(
                        {
                            isDesktop: `(min-width: ${breakPoint}px)`,
                            isMobile: `(max-width: ${breakPoint - 1}px)`
                        },
                        context => {
                            const { isDesktop, isMobile } = context.conditions;
                            if (isDesktop === _desktop || isMobile === _mobile) {
                                ScrollTrigger.create({
                                    trigger: scrollParent,
                                    start: 'top top',
                                    scrub: true,
                                    toggleActions: 'play reverse play reverse',
                                    animation: gsap.fromTo(
                                        el,
                                        { objectPosition: '0%' },
                                        { objectPosition: '100%' }
                                    )
                                });
                            }
                        }
                    );
                } else {
                    el.style.transition = `clip-path ${_duration}ms ease-in-out ${_delay}ms`;
                    el.style.clipPath = `inset(0% ${_name === 'swipe-right' ? '99' : '0'}%
                                0% ${_name === 'swipe-left' ? '99' : '0'}%)`;
                }
                if (_name !== 'pan') {
                    el.style.opacity = '0';

                    if (_onload) {
                        setTimeout(animate, 500);
                    } else {
                        // eslint-disable-next-line no-case-declarations
                        const observer = new IntersectionObserver(callback, {
                            root: null,
                            rootMargin: '0px',
                            threshold: _threshold
                        });
                        observer.observe(el);
                    }
                }
            }

            function callback(entries: IntersectionObserverEntry[]) {
                const ratio = 0.005;
                Array.from(entries).forEach(entry => {
                    if (
                        (window.innerWidth >= breakpointSize('lg') &&
                            entry.intersectionRatio > ratio) ||
                        (window.innerWidth < breakpointSize('lg') &&
                            entry.intersectionRatio > ratio &&
                            entry.intersectionRatio !== 1)
                    ) {
                        animate();
                    }
                });
            }

            function breakpointSize(name: string): number {
                if (Object.prototype.hasOwnProperty.call(twConfig.theme.screens, name)) {
                    return parseInt(twConfig.theme.screens[name]);
                }
                return -1;
            }

            function animate() {
                if (_name.startsWith('slide')) {
                    el.style.transform = 'translateY(0px)';
                } else {
                    el.style.clipPath = 'inset(0% 0% 0% 0%)';
                }
                el.style.opacity = '1';
            }

            function parentSection(element: HTMLElement): HTMLElement {
                if (element.tagName.toLowerCase() === 'section' || element.parentElement === null) {
                    return element;
                }
                return parentSection(element.parentElement);
            }
        }
    }
};
