import { VNode } from 'vue';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';
import { TailwindConfig } from 'tailwindcss/tailwind-config';

export function filterItems(child: VNode) {
    return child.type && typeof child.type === 'object';
}

export function getTwConfig(): TailwindConfig {
    return resolveConfig(tailwindConfig);
}

const getState = (): string[] => {
    return location.hash
        .replace(/^#|\W$/, '')
        .split('&')
        .filter(x => x.length);
};

export const fitSquaresInRect = (w: number, h: number, n: number): number => {
    let sx, sy;
    const px = Math.ceil(Math.sqrt((n * w) / h));
    if (Math.floor((px * h) / w) * px < n) {
        sx = h / Math.ceil((px * h) / w);
    } else {
        sx = w / px;
    }
    const py = Math.ceil(Math.sqrt((n * h) / w));
    if (Math.floor((py * w) / h) * py < n) {
        sy = w / Math.ceil((w * py) / h);
    } else {
        sy = h / py;
    }
    return Math.min(sx, sy);
};

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

const setState = (state: string[]) => {
    window.history.replaceState({}, null, `#${state.join('&')}`);
};

export const addToState = (key: string, value: string) => {
    const state = getState();
    const index = state.findIndex(x => x.split(':').length > 0 && x.split(':')[0] === key);
    if (index < 0) {
        state.push(`${key}:${value}`);
    } else {
        const values = state[index].split(',');
        values.push(value);
        if (values.includes(value)) {
            state[index] = values.join(',');
        }
    }
    setState(state);
};

export const removeFromState = (key: string, value = '') => {
    const state = getState();
    const index = state.findIndex(x => x.split(':').length > 0 && x.split(':')[0] === key);
    if (index >= 0) {
        if (value.length) {
            const values = state[index].replace(/[\w-]+:/, '').split(',');
            const valueIndex = values.findIndex(x => x === value);
            if (valueIndex >= 0) {
                values.splice(valueIndex, 1);
                if (values.length > 0) {
                    state[index] = `${key}:${values.join(',')}`;
                } else {
                    state.splice(index, 1);
                }
            }
        } else {
            state.splice(index, 1);
        }
        setState(state);
    }
};

export const cleanState = () => {
    setState([]);
};

export const validateEmail = email => {
    return !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export function extension(path: string): string {
    if (!path) {
        return '';
    }
    return path.split('.').pop();
}

export function getMimeType(path: string) {
    const ext = extension(path);
    return isVideo(path) ? `video/${ext}` : `image/${ext}`;
}

export function isVideo(path?: string) {
    if (!path) {
        return false;
    }
    const ext = extension(path);
    return ext === 'mp4' || ext === 'm4p' || ext === 'webm';
}

export function shuffleArray(array: unknown[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

/**
 * Set a new browser cookie. Cookie will be valid for 1 year.
 * @param name The name of the cookie
 * @param value The value of the cookie
 * @param days The valid duration of the cookie in days (default: 30)
 */
export function setCookie(name: string, value: string, days = 30) {
    const date: Date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
}

/**
 * Get a browser cookie by a given name
 * Returns null if not found
 * @param name The cookie name
 */
export function getCookie(name: string): string {
    const nameLenPlus: number = name.length + 1;

    return (
        document.cookie
            .split(';')
            .map(c => c.trim())
            .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
            .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null
    );
}
