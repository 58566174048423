
/* eslint-disable prettier/prettier */
import { defineComponent } from 'vue';
import BizIcon from '@/components/base/BizIcon.vue';
import Careers, { Career } from '../../library/Careers';

export default defineComponent({
    components: { BizIcon },
    props: {
        buttonLabel: String,
        seeOverview: String,
        country: String,
        jobCategory: String,
        jobType: String,
        title: String,
        siteLang: Array,
        excludeId: String
    },
    data() {
        return {
            careers: [] as Career[],
            totalResults: 0,
            limit: 3
        };
    },
    mounted() {
        this.loadCareers();

        // animation
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.$refs.othercareers.classList.add('translate-x-0');
                    this.$refs.othercareers.classList.remove('-translate-x-3/4');
                } else {
                    this.$refs.othercareers.classList.add('-translate-x-3/4');
                    this.$refs.othercareers.classList.remove('translate-x-0');
                }
            });
        }, {
            threshold: 0.1,
            rootMargin: '-40px'
        });
        observer.observe(this.$refs.othercareers);
    },
    methods: {
        async loadCareers() {
            this.loading = true;
            try {
                const filters: { country?: string; jobType?: string; jobCategory?: string } = {}
                if (this.country) {
                    filters.country = this.country;
                }
                if (this.jobType) {
                    filters.jobType = this.jobType;
                }
                if (this.jobCategory) {
                    filters.jobCategory = this.jobCategory;
                }

                // Load 3 careers with filters
                this.careers = await Careers.loadCareers('title-asc', this.limit, filters, this.siteLang, this.excludeId);
                // If doesn't have 3 careers filter try to load without the jobCategory
                if(this.careers.length < this.limit && this.jobCategory){
                    delete filters.jobCategory;
                    this.careers = await Careers.loadCareers('title-asc', this.limit, filters, this.siteLang, this.excludeId);
                }
            } catch (e) {
                console.error(e);
            }
            this.loading = false;
        }
    },
    computed: {
        parentHash() {
            const params = [];
            if (this.country) {
                params.push(`country:${encodeURIComponent(this.country)}`);
            }
            if (this.jobType) {
                params.push(`jobType:${encodeURIComponent(this.jobType)}`);
            }
            if (this.jobCategory) {
                params.push(`jobCategory:${encodeURIComponent(this.jobCategory)}`);
            }
            return params.length ? `#${params.join('&')}` : '';
        },
        parentUrl() {
            const currentUrl = window.location.pathname;
            const segments = currentUrl.split('/').filter(Boolean);
            segments.pop();
            return '/' + segments.join('/');
        }
    }
});
