
import Swiper, { Autoplay, Mousewheel, SwiperOptions } from 'swiper';
import {
    ComponentPublicInstance,
    computed,
    watch,
    defineComponent,
    h,
    onMounted,
    PropType,
    provide,
    reactive,
    ref,
    toRefs,
    VNode
} from 'vue';
import { v4 as uuid } from 'uuid';
import { getTwConfig } from '@/utils';

export default defineComponent({
    props: {
        direction: {
            type: String as PropType<'horizontal' | 'vertical'>,
            default: () => 'horizontal'
        },
        loop: {
            type: Boolean,
            default: false
        },
        gap: {
            type: [Number, Object] as PropType<number | { [breakpoint: string]: number }>,
            default: () => 0
        },
        containerClasses: {
            type: String as PropType<string>,
            default: ''
        },
        slideClasses: {
            type: String as PropType<string>,
            default: ''
        },
        wrapperClasses: {
            type: String as PropType<string>,
            default: ''
        },
        slideTag: {
            type: String as PropType<string>,
            default: 'div'
        },
        wrapperTag: {
            type: String as PropType<string>,
            default: 'div'
        },
        start: {
            type: Number as PropType<number>,
            default: 0
        },
        speed: {
            type: Number as PropType<number>,
            default: 300
        },
        slidesOffsetAfter: {
            type: Number as PropType<number>,
            default: 0
        },
        centered: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        autoplay: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        autoplayDisableOnClick: {
            type: Boolean as PropType<boolean>,
            default: true
        },
        allowTouchMove: {
            type: Boolean as PropType<boolean>,
            default: true
        },
        autoHeight: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        editMode: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        slidesPerView: {
            type: [Number, Object, String],
            default: 'auto'
        },
        mousewheel: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        slideToClickedSlide: {
            type: Boolean as PropType<boolean>,
            default: true
        }
    },
    emits: ['index-changed'],
    setup(props, { emit, slots, expose }) {
        const container = ref(null);
        const state = reactive({
            slides: [],
            swiper: null,
            index: 0,
            autoplayOn: props.autoplay,
            goToClick: false,
            uid: uuid(),
            options: {
                watchOverflow: true,
                wrapperClass: 'slider-wrapper',
                slideClass: 'slider-slide',
                observer: true,
                loop: props.loop || props.autoplay, // always loop in autoplay
                speed: props.speed,
                allowTouchMove: props.allowTouchMove,
                autoplay: props.autoplay ? { delay: 5000 } : false,
                autoHeight: props.autoHeight,
                direction: props.direction,
                touchReleaseOnEdges: true,
                initialSlide: props.start,
                observeParents: true,
                mousewheel: props.mousewheel,
                watchSlidesProgress: true,
                slideActiveClass: 'slide-active',
                slideVisibleClass: 'slide-visible',
                slideToClickedSlide: props.slideToClickedSlide,
                threshold: 20
            } as SwiperOptions,
            twConfig: getTwConfig()
        });

        onMounted(() => {
            if (props.editMode) {
                return;
            }
            setBreakpointOption(props.direction, 'direction');
            setBreakpointOption(props.gap, 'spaceBetween');
            setBreakpointOption(props.slidesPerView, 'slidesPerView');
            setBreakpointOption(props.slidesOffsetAfter, 'slidesOffsetAfter');
            setBreakpointOption(props.centered, 'centeredSlides');
            if (props.autoplay) {
                Swiper.use([Autoplay]);
            }
            if (props.mousewheel) {
                Swiper.use([Mousewheel]);
            }
            state.swiper = new Swiper(container.value, state.options);
            state.swiper.on('activeIndexChange', indexChanged);
        });

        const register = (slide: ComponentPublicInstance) => {
            state.slides.push(slide);
            if (slots.default().filter(filterSlides).length === state.slides.length) {
                // do init
            }
        };

        provide('registerSlide', register);

        const indexChanged = ({ activeIndex, realIndex }) => {
            state.index = props.loop || props.autoplay ? realIndex : activeIndex;
            // FIXME: workaround to swiper bug not setting visible classes correctly
            // forces it to calculate classes again
            setTimeout(() => {
                state.swiper.slideReset();
            });
            // enable autoplay if changed without goTo
            if (props.autoplay && !state.goToClick && !state.swiper.autoplay.running) {
                state.swiper.autoplay.run();
                state.autoplayOn = true;
            }
            state.goToClick = false;
            emit('index-changed', state.index);
        };

        const next = () => {
            if (state.swiper) {
                if (state.swiper.isEnd) {
                    return;
                }
                if (props.loop) {
                    state.index =
                        state.index + 1 >= state.swiper.slides.length ? 0 : state.index + 1;
                } else if (state.index + 1 < state.swiper.slides.length) {
                    state.index++;
                }
                state.swiper.slideNext();
            }
        };

        const prev = () => {
            if (state.swiper) {
                if (state.swiper.isBeginning) {
                    return;
                }
                if (props.loop) {
                    state.index =
                        state.index > 0 ? state.index - 1 : state.swiper.slides.length - 1;
                } else if (state.index > 0) {
                    state.index--;
                }
                state.swiper.slidePrev();
            }
        };

        const goTo = index => {
            if (state.swiper) {
                state.swiper.slideTo(index);
                state.index = index;
            }
            // disable autoplay
            if (props.autoplayDisableOnClick) {
                state.goToClick = true;
                state.autoplayOn = false;
                state.swiper.autoplay.stop();
            }
        };

        const breakpointSize = (name: string): number => {
            if (Object.prototype.hasOwnProperty.call(state.twConfig.theme.screens, name)) {
                return parseInt(state.twConfig.theme.screens[name]);
            }
            return -1;
        };

        const setBreakpointOption = (value, name) => {
            const breakpoints = state.options.breakpoints || {};
            if (typeof value === 'number' || typeof value === 'string') {
                state.options[name] = value;
                return;
            }
            Object.entries(value).forEach(x => {
                const bp = breakpointSize(x[0]);
                if (bp > 0) {
                    if (!Object.prototype.hasOwnProperty.call(breakpoints, bp)) {
                        breakpoints[bp] = {};
                    }
                    breakpoints[bp][name] = x[1];
                } else {
                    state.options[name] = x[1];
                }
            });
            if (!state.options.breakpoints) {
                state.options.breakpoints = breakpoints;
            }
        };

        // check children to only count slide components
        const filterSlides = (child: VNode) => {
            return child.type && typeof child.type === 'object';
        };

        const numSlides = computed(() => {
            if (slots.default) {
                return slots
                    .default()
                    .flatMap(x =>
                        typeof x.type === 'symbol' && x.children.length ? x.children : [x]
                    )
                    .filter(x => typeof (x as VNode).type === 'object').length;
            }
            return 0;
        });

        const currentSlide = computed(() => {
            const index =
                state.index - props.start < 0 ? state.slides.length - 1 : state.index - props.start;
            if (state.slides.length && state.slides.length > index) {
                return state.slides[index];
            }
            return null;
        });

        const _wrapperClasses = computed(() => {
            if (typeof props.direction === 'string') {
                return props.direction === 'vertical' ? 'flex-col' : 'flex-row';
            }
            // md:flex-col lg:flex-col xl:flex-col md:flex-row lg:flex-row xl:flex-row
            const classes = [];
            Object.entries(props.direction).forEach(x => {
                const bp = breakpointSize(x[0]);
                const dir = x[1] === 'vertical' ? 'flex-col' : 'flex-row';
                if (bp > 0) {
                    classes.push(`${x[0]}:${dir}`);
                } else {
                    classes.push(dir);
                }
            });
            return classes.join(' ');
        });

        expose({ ...toRefs(state), goTo, prev, next, container, numSlides });

        watch(
            () => props.start,
            () => {
                goTo(props.start);
            }
        );

        return () =>
            h('div', { class: 'w-full h-full' }, [
                h(
                    'div',
                    {
                        class: [
                            'slider-container h-full w-full overflow-hidden',
                            props.containerClasses
                        ]
                            .filter(x => x.length)
                            .join(' '),
                        ref: container
                    },
                    [
                        h(
                            props.wrapperTag,
                            {
                                class: [
                                    'slider-wrapper',
                                    props.editMode ? '' : 'flex flex-nowrap h-full',
                                    _wrapperClasses,
                                    props.wrapperClasses,
                                    props.autoHeight ? 'items-start' : ''
                                ]
                                    .filter((x: string) => x.length)
                                    .join(' ')
                            },
                            slots.default
                                ? slots
                                      .default()
                                      .flatMap(x =>
                                          typeof x.type === 'symbol' && x.children.length
                                              ? x.children
                                              : [x]
                                      )
                                      .filter(x => typeof (x as VNode).type === 'object')
                                      .map((el, i) =>
                                          h(
                                              props.slideTag,
                                              {
                                                  class: `slider-slide shrink-0 max-w-full ${
                                                      props.slideClasses
                                                  }${state.index === i ? ' slide-active' : ''}`
                                              },
                                              [
                                                  h(el, {
                                                      onSlideClick: e => {
                                                          const index = state.slides.findIndex(
                                                              x => x.id === e.id
                                                          );
                                                          if (
                                                              index >= 0 &&
                                                              index < state.slides.length
                                                          ) {
                                                              goTo(index);
                                                          }
                                                      }
                                                  })
                                              ]
                                          )
                                      )
                                : []
                        )
                    ]
                ),
                slots.pagination && !props.editMode
                    ? slots.pagination({
                          index: state.index + 1,
                          total: numSlides.value,
                          slides: state.slides,
                          go: goTo,
                          prev: prev,
                          next: next,
                          uid: state.uid,
                          autoplay: state.autoplayOn
                      })
                    : null,
                slots.controls
                    ? slots.controls({
                          prev: prev,
                          next: next,
                          total: numSlides.value,
                          index: state.index,
                          beginning: state.swiper ? state.swiper.isBeginning : false,
                          end: state.swiper ? state.swiper.isEnd : false
                      })
                    : null
            ]);
    }
});
