import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "lg:grid lg:grid-cols-6" }
const _hoisted_2 = { class: "col-span-4" }
const _hoisted_3 = { class: "lg:flex lg:justify-end" }
const _hoisted_4 = { class: "mt-8 lg:mt-11" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "header")
      ]),
      (!_ctx.open)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open)),
            class: "col-span-2"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "open")
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.hero && _ctx.dropdown)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["relative overflow-hidden transition-all duration-500", _ctx.open ? 'max-h-full' : 'max-h-32']),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = true))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([[
                _ctx.open ? 'hidden bg-transparent' : 'bg-gradient-to-t pt-8',
                _ctx.color ? 'from-dark-blue via-dark-blue/40' : 'from-gray-200 via-gray-200/40'
            ], "absolute bottom-0 z-10 h-16 w-full transition-all duration-1000 lg:from-white lg:via-white/40"])
          }, null, 2),
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : (_openBlock(), _createBlock(_component_collapse_transition, { key: 1 }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default")
            ], 512), [
              [_vShow, _ctx.open]
            ])
          ]),
          _: 3
        }))
  ], 64))
}