import mitt from 'mitt';

type Event = {
    name: string;
    payload: unknown;
};

const emitter = mitt<Event>();

export default {
    $on: (name, payload) => emitter.on(name, payload),
    $off: (name, payload) => emitter.off(name, payload),
    $emit: (name, payload) => emitter.emit(name, payload)
};
