
import { defineComponent } from 'vue';
import JSShare from 'js-share';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    components: { BizIcon },
    props: {
        contrast: {
            type: Boolean,
            default: false
        },
        share: {
            type: String
        }
    },
    data() {
        return {
            hidden: true,
            icons: ['facebook', 'linkedin', 'whatsapp', 'email'],
            open: false
        };
    },
    mounted() {
        for (let i = 0; i < this.$refs.shareItems.length; i += 1) {
            this.$refs.shareItems[i].addEventListener('click', function share(e) {
                if (this.dataset.type === 'xing') {
                    // custom handler for xing since its not supported by the library
                    const url = `https://www.xing.com/spi/shares/new?url=${encodeURIComponent(
                        location.href
                    )}`;
                    window.open(url, '_blank');
                    return;
                }
                return JSShare.go(this);
            });
        }
    },
    methods: {
        copy() {
            navigator.clipboard.writeText(location.href);
            alert('Page link has been copied to clipboard!');
        },
        print() {
            window.print();
        }
    },
    watch: {
        hidden(newValue) {
            if (newValue) {
                this.open = false;
            }
        }
    }
});
