
import { gsap } from 'gsap';
import { defineComponent } from 'vue';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    components: { BizIcon },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        show: Boolean
    },
    data() {
        return {
            activeItem: -1,
            categories: [],
            tween: null
        };
    },
    mounted() {
        this.activeItem = -1;
    },
    methods: {
        onItemClick(item, index, event) {
            if (this.activeItem === index || item.subItems.length === 0) {
                return;
            }
            this.activeItem = index;
            const container = this.$el.getBoundingClientRect();
            const el = event.currentTarget.getBoundingClientRect();
            this.tween = gsap.to(event.currentTarget, {
                x: container.x - el.x,
                y: container.y - el.y,
                width: container.width,
                minHeight: `${container.height}px`,
                duration: 0.5,
                delay: this.activeItem === -1 ? 0.3 : 0
            });
        },
        inactiveClass(index) {
            const classes = {
                'transition-transform': this.activeItem !== index,
                'delay-500': this.activeItem === -1
            };
            if (this.activeItem >= 0) {
                classes['scale-0 pointer-events-none'] = this.activeItem !== index;
            }
            return classes;
        },
        close() {
            this.activeItem = -1;
            if (this.tween) {
                this.tween.reverse().then(() => {
                    this.tween.kill();
                    this.tween = null;
                });
            }
        }
    },
    computed: {
        _uid() {
            return this._uid;
        }
    },
    watch: {
        show() {
            if (!this.show) {
                this.close();
            }
        }
    }
});
