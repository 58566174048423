
import { defineComponent } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import MatchMedia = gsap.MatchMedia;
import { getTwConfig } from '@/utils';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    components: { BizIcon },
    props: {
        moreContentLabel: String
    },
    data() {
        return {
            open: false,
            hidden: true,
            trigger: null,
            twConfig: getTwConfig()
        };
    },
    created() {
        gsap.registerPlugin(ScrollTrigger);
    },
    mounted() {
        if (!this.editMode) {
            const mm: MatchMedia = gsap.matchMedia();
            const breakPoint = parseInt(this.twConfig.theme.screens.lg);

            mm.add(
                {
                    isDesktop: `(min-width: ${breakPoint}px)`,
                    isMobile: `(max-width: ${breakPoint - 1}px)`
                },
                context => {
                    const { isDesktop } = context.conditions;
                    if (isDesktop) {
                        this.trigger = document.querySelector('[data-trigger=subNav]');
                        if (!this.trigger) {
                            return;
                        }
                        ScrollTrigger.create({
                            trigger: this.trigger,
                            start: 'top center',
                            toggleActions: 'play reverse play reverse',
                            onToggle: scrollTrigger => {
                                this.hidden = !scrollTrigger.isActive;
                            },
                            onRefresh: scrollTrigger => {
                                this.hidden = !scrollTrigger.isActive;
                            },
                            animation: gsap.fromTo(this.$el, { x: '100%' }, { x: 0 })
                        });
                    }
                }
            );
        }
    },
    methods: {
        toggle() {
            this.open = !this.open;
        }
    },
    watch: {
        hidden() {
            if (this.hidden) {
                this.open = false;
            }
        }
    }
});
