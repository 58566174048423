
import { defineComponent } from 'vue';
import { v4 as uuid } from 'uuid';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    components: { BizIcon },
    props: {
        items: Array,
        backLabel: String
    },
    data() {
        return {
            clickedIndex: 0
        };
    },
    methods: {
        changeClickedIndex(index) {
            this.clickedIndex = index;
        }
    },
    computed: {
        id() {
            return `productsliders-${uuid()}`;
        }
    }
});
