import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-block py-4 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_accordion = _resolveComponent("biz-accordion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["hidden", [_ctx.active ? 'lg:block' : 'lg:hidden']])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _createVNode(_component_biz_accordion, { class: "lg:hidden" }, {
      title: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1)
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ], 64))
}