
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        stickyText: {
            type: Number,
            required: true
        },
        contrast: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            globalPosition: 0,
            position: 0
        };
    },
    methods: {
        handleScroll: function (event) {
            this.globalPosition = window.scrollY;
            this.position = -this.$el.getBoundingClientRect().y;
            this.maxHeight = this.$el.getBoundingClientRect().height - 100;
        }
    },
    created: function () {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted: function () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
        stickyPosition() {
            if (this.position < 0) return '0%';
            else if (this.position < this.maxHeight)
                return (this.position * 100) / this.maxHeight + '%';
            else return '100%';
        },
        inactive() {
            return this.position < -200 || this.position > this.maxHeight - 50;
        }
    }
});
