
import { defineComponent } from 'vue';
import { filterItems, getTwConfig } from '@/utils';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    components: { BizIcon },
    props: {
        link: String,
        linkLabel: String,
        title: String,
        text: String,
        target: String,
        teaserKicker: String,
        teaserTitle: String,
        teaserLink: String,
        teaserImage: String,
        teaserImageAlt: String,
        cards: {
            type: Boolean,
            default: false
        },
        subitems: {
            type: Boolean,
            default: true
        }
    },
    inject: ['registerMainNavItem', 'hoverMainNavItem', 'clickMainNavItem'],
    provide() {
        return {
            registerNavItem: this.register
        };
    },
    data() {
        return {
            items: [],
            active: false,
            ready: false,
            twConfig: getTwConfig()
        };
    },
    mounted() {
        this.registerMainNavItem(this);
    },
    methods: {
        register(item) {
            this.items.push(item);
            if (this.$slots.default().filter(filterItems).length === this.items.length) {
                this.ready = true;
            }
        },
        click() {
            if (this.subitems) {
                this.clickMainNavItem(this);
            } else {
                window.location.href = this.link;
            }
        },
        hover() {
            if (window.innerWidth >= parseInt(this.twConfig.theme.screens.lg) && this.subitems) {
                this.hoverMainNavItem(this);
            }
        }
    },
    computed: {},
    watch: {}
});
