
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        items: {
            type: Array
        },
        placeholderClass: {
            type: String,
            default: ''
        },
        name: String,
        label: String,
        validateText: String,
        notvalid: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        defaultId: String
    },
    data() {
        return {
            isOpen: false,
            results: [],
            search: '',
            value: '',
            changeName: 'changeName',
            arrowCounter: 0
        };
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        const findIntro = this.items.filter(item => {
            return item.id === this.defaultId;
        });
        if (findIntro[0] && findIntro[0].label) {
            this.search = findIntro[0].label;
            this.value = this.defaultId;
        }

        // edge don't read the autocomplete
        // solution have a random name in the input and then change to the original input name
        // solution for windows chrome -> autocomplete="one-time-code"
        setTimeout(() => {
            this.changeName = this.name;
        }, 500);
    },

    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },

    methods: {
        filterResults() {
            this.results = this.items.filter(item => {
                return item.label.toLowerCase().includes(this.search.toLowerCase());
            });
        },
        onChange() {
            this.setValue();
            this.$emit('input', this.search);
            this.filterResults();
            this.isOpen = true;
        },
        setResult(result) {
            this.search = result.label;
            this.isOpen = false;
            this.setValue();
        },
        setValue() {
            if (this.search.length) {
                const item = this.items.find(x => x.label === this.search);
                if (item) {
                    this.value = item.id;
                    return;
                }
            }
            this.value = '';
        },
        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },

        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },

        onEnter(event) {
            this.search = this.results[this.arrowCounter];
            this.isOpen = false;
            this.arrowCounter = -1;
            if (event.key === 'Enter' && this.results[0]) {
                this.pickFirst();
            }
        },

        pickFirst() {
            this.search = this.results[0].label;
            this.isOpen = false;
            this.setValue();
        },

        handleBlur() {
            if (this.value === '') {
                this.search = '';
            }
        },

        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        }
    },
    watch: {
        items(val, oldValue) {
            if (val.length !== oldValue.length) {
                this.results = val.label;
            }
        }
    }
});
