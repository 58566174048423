
import { defineComponent } from 'vue';
import { filterItems } from '@/utils';

export default defineComponent({
    props: {},
    data() {
        return {
            items: []
        };
    },
    provide() {
        return {
            registerProductVariant: this.register
        };
    },
    methods: {
        register(item) {
            this.items.push(item);
            if (
                this.$slots.default().filter(filterItems).length === this.items.length &&
                this.items.length > 0
            ) {
                this.items[0].active = true;
            }
        },
        onHover(item) {
            this.items.forEach(i => {
                i.active = false;
            });
            item.active = true;
        }
    }
});
