
import { defineComponent } from 'vue';
import { getCookie, validateEmail } from '@/utils';

const FORM_ENDPOINT = '/.rest/api/v1/cta';

export default defineComponent({
    props: {
        fieldset: {
            type: String,
            default: 'basic'
        }
    },
    data() {
        return {
            notvalid: false,
            submitted: false,
            success: false,
            loading: false,
            fileSizeError: false,
            fileCountError: false,
            captchaSolved: false,
            files: []
        };
    },
    methods: {
        async submit({ target }) {
            this.notvalid = false;
            const form: HTMLFormElement =
                target.tagName.toLowerCase() === 'form' ? target : target.form;
            const formInputs = target.elements;
            for (let i = 0; i < formInputs.length; i++) {
                if (
                    (formInputs[i].required && !formInputs[i].value) ||
                    (formInputs[i].type === 'email' && validateEmail(formInputs[i].value)) ||
                    (formInputs[i].type === 'checkbox' && !formInputs[i].checked)
                ) {
                    this.notvalid = true;
                }
            }
            if (!this.notvalid && !this.fileCountError && !this.fileSizeError) {
                this.loading = true;
                this.submitted = false;
                const fd: FormData = new FormData(form);
                const campaign = getCookie('utm_campaign');
                let topicExternalSource = fd.get('topicExternalSource');
                // set distributor field
                if (this.fieldset === 'pro') {
                    topicExternalSource = `${fd.get('distributor')}${topicExternalSource}`;
                }
                // set campaign field
                if (campaign) {
                    topicExternalSource += `, utm_campaign: ${campaign}`;
                }
                fd.set('topicExternalSource', topicExternalSource);

                if (this.files.length) {
                    fd.set('filename', this.files.map(x => x.name).join(','));
                    this.files.forEach((file: File, index: number) => {
                        fd.set(`file${index + 1}`, file);
                    });
                    fd.delete('file');
                }

                try {
                    const res = await fetch(
                        `${this.$contextPath}${FORM_ENDPOINT}/${this.$siteName}/${this.$lang}`,
                        {
                            method: 'POST',
                            body: fd
                        }
                    );
                    const data = await res.json();
                    this.success = data.success;
                } catch (e) {
                    this.success = false;
                }
                this.submitted = true;
                this.loading = false;
            }
        },
        selectFile({ target }: { target: HTMLInputElement }) {
            const files = target.files;
            // add files
            for (let i = 0; i < files.length; i++) {
                this.files.push(files[i]);
            }
            this.udpateFile();
        },
        removeFile(fileToRemove) {
            this.files = this.files.filter(file => file !== fileToRemove);
            // clean input so it is possible to add the same file again
            const inputFile = document.getElementById('file') as HTMLInputElement;
            if (inputFile) {
                inputFile.value = '';
            }
            this.udpateFile();
        },
        udpateFile() {
            this.fileCountError = this.files.length > 3;
            let fileSize = 0;
            for (let i = 0; i < this.files.length; i++) {
                fileSize += this.files[i].size;
            }
            this.fileSizeError = fileSize > 10 * 1024 * 1024;
        },
        onCaptchaSolved(success: boolean) {
            this.captchaSolved = success;
        }
    }
});
