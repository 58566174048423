import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown-scrollbar absolute left-0 top-full z-30 mt-px max-h-[10.5rem] w-full overflow-y-auto overflow-x-hidden bg-white shadow-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative border-b pb-2 pt-4", [
            _ctx.activeTitle ? 'text-gray-500' : 'text-gray-400',
            {
                'border-white': _ctx.contrast,
                'border-white lg:border-current': _ctx.contrastMobile,
                'border-current': !(_ctx.contrast || _ctx.contrastMobile),
                'border-red lg:border-red': _ctx.redBorder,
                'pointer-events-none opacity-50': _ctx.disabled
            }
        ]])
  }, [
    _createElementVNode("div", {
      class: "flex cursor-pointer items-center justify-between",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["absolute origin-left transition-transform", [
                    {
                        'text-white': _ctx.contrast,
                        'text-white lg:text-gray-400': _ctx.contrastMobile,
                        'text-gray-400': !(_ctx.contrast || _ctx.contrastMobile),
                        '-translate-y-full scale-75': _ctx.activeValue.length,
                        'hidden': _ctx.hiddenTitle && _ctx.activeValue.length
                    }
                ]])
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("span", {
        class: _normalizeClass(["line-clamp-1", {
                    'text-white': _ctx.contrast,
                    'text-white  lg:text-gray-500': _ctx.contrastMobile,
                    'text-gray-500': !(_ctx.contrast || _ctx.contrastMobile)
                }])
      }, _toDisplayString(_ctx.activeTitle || ' '), 3),
      _createVNode(_component_biz_icon, {
        name: "chevron-right",
        class: _normalizeClass([[
                    _ctx.open ? '-rotate-90' : 'rotate-90',
                    {
                        'fill-white': _ctx.contrast,
                        'fill-white  lg:fill-gray-500': _ctx.contrastMobile,
                        'fill-current': !(_ctx.contrast || _ctx.contrastMobile)
                    }
                ], "h-4 w-4 fill-current transition-transform"])
      }, null, 8, ["class"])
    ]),
    _createVNode(_component_collapse_transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ], 512), [
          [_vShow, _ctx.open]
        ])
      ]),
      _: 3
    }),
    _renderSlot(_ctx.$slots, "selection", { value: _ctx.activeValue })
  ], 2))
}