import events from '@/events';

export default {
    mounted(element: HTMLElement, binding) {
        // create event listener
        element.addEventListener('click', e => {
            e.preventDefault();
            events.$emit('open-popup', binding.arg ? binding.arg : binding.value);
        });
    }
};
