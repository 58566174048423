import { vModelText as _vModelText, withKeys as _withKeys, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["placeholder", "required"]
const _hoisted_4 = ["id", "name", "value"]
const _hoisted_5 = { class: "dropdown-scrollbar absolute top-0 z-10 max-h-32 w-full overflow-auto pt-10 text-gray-500 shadow-lg" }
const _hoisted_6 = { class: "bg-white" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "form-box",
      for: _ctx.changeName
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "search",
        placeholder: _ctx.label,
        class: _normalizeClass(["form-input", { 'form-input-validate': _ctx.notvalid }]),
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
        autocomplete: "one-time-code",
        required: _ctx.required,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
        onKeyup: [
          _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onArrowDown && _ctx.onArrowDown(...args)), ["down"])),
          _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onArrowUp && _ctx.onArrowUp(...args)), ["up"])),
          _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["stop"]), ["enter"]))
        ]
      }, null, 42, _hoisted_3), [
        [_vModelText, _ctx.search]
      ]),
      _createElementVNode("input", {
        type: "hidden",
        id: _ctx.name,
        name: _ctx.changeName,
        value: _ctx.value
      }, null, 8, _hoisted_4),
      _createElementVNode("span", {
        class: _normalizeClass(["form-placeholder", _ctx.placeholderClass])
      }, _toDisplayString(_ctx.label), 3),
      (_ctx.notvalid)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["form-validate", _ctx.placeholderClass])
          }, _toDisplayString(_ctx.validateText), 3))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createElementVNode("ul", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: i,
            onClick: ($event: any) => (_ctx.setResult(result)),
            class: "cursor-pointer px-4 py-2 text-gray-400 hover:bg-gray-100"
          }, _toDisplayString(result.label), 9, _hoisted_7))
        }), 128))
      ])
    ], 512), [
      [_vShow, _ctx.isOpen]
    ])
  ]))
}