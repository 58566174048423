import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "invisible"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hover && _ctx.hover(...args))),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
    class: "group relative flex h-full cursor-pointer items-center justify-between font-light"
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("span", {
      class: _normalizeClass([{ 'lg:scale-100': _ctx.active }, "absolute bottom-0 left-0 w-full scale-0 transition-transform duration-300 lg:border-b-2 lg:border-current lg:group-hover:scale-100"])
    }, null, 2),
    (!_ctx.ready)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_biz_icon, {
      name: "chevron-right",
      class: "h-3 w-3 lg:hidden"
    })
  ], 32))
}