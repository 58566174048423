
import { defineComponent } from 'vue';

export default defineComponent({
    props: {},
    data() {
        return {
            autoCols: false,
            leftLines: 0,
            rightLines: 0,
            text: '',
            timer: -1
        };
    },
    mounted() {
        this.text = this.$refs.content.innerText;
        this.$nextTick(() => {
            this.countLines();
        });
        window.addEventListener('resize', this.recalculate);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.recalculate);
    },
    methods: {
        countLines() {
            this.leftLines = Math.ceil(
                this.$refs.leftCol.getBoundingClientRect().height /
                    parseInt(getComputedStyle(this.$refs.leftCol).lineHeight)
            );
            this.rightLines = Math.ceil(
                this.$refs.rightCol.getBoundingClientRect().height /
                    parseInt(getComputedStyle(this.$refs.rightCol).lineHeight)
            );
            // if both columns are the same height we can use css columns
            this.autoCols = this.rightLines > this.leftLines;
        },
        // count the lines again after resize
        recalculate() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                // first reset everything so we get the original text
                this.text = '';
                this.$nextTick(() => {
                    this.text = this.$refs.content.innerText;
                    this.autoCols = false;
                    this.$nextTick(() => {
                        this.countLines();
                    });
                });
            }, 200);
        }
    },
    computed: {
        initial(): string {
            if (this.text.length) {
                return this.text.slice(0, 1);
            }
            return '';
        },
        leftColText(): string {
            if (this.autoCols) {
                return this.fullText;
            }
            if (this.text.length) {
                // 360 characters seems to be around 6 lines (= height of the initial letter)
                return this.text.slice(1, 350);
            }
            return '';
        },
        rightColText(): string {
            if (this.text.length >= 350) {
                return this.text.slice(350);
            }
            return '';
        },
        fullText(): string {
            if (this.text.length > 1) {
                return this.text.slice(1);
            }
            return '';
        }
    }
});
