
import { defineComponent } from 'vue';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    components: { BizIcon },
    props: {
        name: String
    },
    data() {
        return {
            searchInput: ''
        };
    },
    inject: ['toggleOverviewSearch'],
    methods: {
        toggleSearch() {
            this.toggleOverviewSearch(this.searchInput);
        }
    }
});
