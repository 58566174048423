/**
 * Fade-in directive
 *
 * Fades in a HTMlElement as it scrolls into view
 * Usage: <div v-fade-in:delay></div> where delay (in ms) is an optional parameter
 * Relies on css classes defined in fade.css
 *
 * @author Julian Frank (julian.frank@lemonize.de)
 */

function playPause(entries: IntersectionObserverEntry[]) {
    const windowWidth = window.innerWidth;
    const ratio = 0.5;

    Array.from(entries).forEach(entry => {
        const target: HTMLVideoElement = entry.target as HTMLVideoElement;
        if (entry.intersectionRatio > ratio) {
            target.play();
        } else {
            target.pause();
        }
    });
}

export default {
    mounted(element: HTMLElement) {
        if (element.tagName.toLowerCase() !== 'video') {
            return;
        }
        const observer = new IntersectionObserver(playPause, {
            root: null,
            rootMargin: '0px 0px',
            threshold: 0.5
        });
        observer.observe(element);
    }
};
