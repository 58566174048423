
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import BizIcon from '@/components/base/BizIcon.vue';
export default defineComponent({
    components: { BizIcon, CollapseTransition },
    props: {
        contrast: Boolean
    },
    data() {
        return {
            open: false
        };
    },
    mounted() {
        document.addEventListener('click', this.onOutsideClick);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.onOutsideClick);
    },
    methods: {
        onOutsideClick(evt) {
            let targetElement = evt.target;
            do {
                if (targetElement === this.$el) {
                    return;
                }
                targetElement = targetElement.parentNode;
            } while (targetElement);
            this.open = false;
        }
    }
});
