
import { defineComponent } from 'vue';
import HeroTextMediaColumnsItem from '@/components/HeroTextMediaColumnsItem.vue';
import { filterItems } from '@/utils';

export default defineComponent({
    props: {
        components: {
            type: Number,
            default: 4
        },
        contrast: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            items: [],
            index: -1
        };
    },
    provide() {
        return {
            registerHeroTextMediaColumnsItem: this.register,
            hoverHeroTextMediaColumnsItem: this.hoverItem
        };
    },
    methods: {
        register(item: InstanceType<typeof HeroTextMediaColumnsItem>) {
            this.items.push(item);
            if (
                this.$slots.default().filter(filterItems).length === this.items.length &&
                this.items.length > 0
            ) {
                this.items[0].showImage = true;
            }
            return this.items.length - 1;
        },
        hoverItem(index, enter = true) {
            this.index = enter ? this.items.findIndex((x, i) => i === index) : -1;
        }
    },
    computed: {
        // lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 lg:grid-cols-5 lg:grid-cols-6
        gridColumns() {
            return Math.min(this.components, 6);
        }
    },
    watch: {
        index() {
            this.items.forEach(x => {
                x.active = false;
                x.showImage = false;
            });
            if (this.index === -1 && this.items.length > 0) {
                this.items[0].showImage = true;
                return;
            }
            if (this.items.length > this.index && this.index >= 0) {
                this.items[this.index].active = true;
            }
        }
    }
});
