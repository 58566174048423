
import { defineComponent } from 'vue';
import { v4 as uuid } from 'uuid';

export default defineComponent({
    props: {
        index: Number,
        id: {
            type: String,
            default: () => uuid()
        },
        title: String,
        text: String,
        url: String,
        target: String,
        label: String,
        image: String,
        editMode: {
            type: Boolean,
            default: false
        },
        enableClick: {
            type: Boolean,
            default: false
        },
        mousewheel: {
            type: Boolean,
            default: false
        }
    },
    inject: {
        registerSlide: {
            default: () => () => null
        }
    },
    data() {
        return {
            show: true,
            active: false
        };
    },
    mounted() {
        if (!this.editMode) {
            this.registerSlide(this);
        }

        const _stageVideo = this.$el.querySelector('video');
        if (_stageVideo != null) {
            _stageVideo.setAttribute('muted', 'true');
        }
    },
    methods: {
        onClick() {
            if (this.enableClick) {
                this.$emit('slide-click', this);
            }
        }
    },
    computed: {
        imageUrl(): string {
            if (this.image) {
                return this.image.startsWith(this.$contextPath)
                    ? this.image
                    : `${this.$contextPath}${this.image}`;
            }
            return '';
        }
    }
});
