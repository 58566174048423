import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-4 line-clamp-1" }
const _hoisted_2 = { class: "dropdown-scrollbar absolute left-0 top-full z-30 mt-px max-h-[10.5rem] w-full overflow-y-auto overflow-x-hidden bg-white shadow-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative max-w-full flex-auto py-4", _ctx.contrast ? 'bg-black' : 'bg-gray-350'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex cursor-pointer items-center justify-between", _ctx.contrast ? 'text-white' : 'text-gray-400 opacity-50']),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
    }, [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _createVNode(_component_biz_icon, {
        name: "chevron-right",
        class: _normalizeClass([_ctx.open ? '-rotate-90' : 'rotate-90', "mr-4 h-4 w-4 fill-current transition-transform"])
      }, null, 8, ["class"])
    ], 2),
    _createVNode(_component_collapse_transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ], 512), [
          [_vShow, _ctx.open]
        ])
      ]),
      _: 3
    })
  ], 2))
}