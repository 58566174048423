export default {
    mounted(element: HTMLElement, binding) {
        // create event listener
        element.addEventListener('click', () => {
            // the video and image need to be inside the div with v-hide
            const iframe = element.querySelector('iframe') as HTMLIFrameElement;
            const video = element.querySelector('video') as HTMLVideoElement;
            // a unique class of the image has to be passed in v-hide="''"
            const image = element.getElementsByClassName(binding.value);

            if (image[0]) {
                if (iframe) {
                    iframe.src += '&autoplay=1';
                }
                if (video) {
                    video.play();
                }
                image[0].classList.add('hidden');
            }
        });
    }
};
