import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, withCtx as _withCtx, vModelText as _vModelText, withKeys as _withKeys, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container fixed bottom-0 left-0 z-40 w-full overflow-hidden bg-gray-500 px-8 py-6 transition-transform lg:relative lg:bg-transparent" }
const _hoisted_2 = { class: "flex flex-row-reverse items-center justify-start border-b pt-2 text-white lg:w-2/3 lg:flex-row lg:justify-between lg:border-gray-400 lg:text-gray-400" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "mt-10 text-white lg:hidden"
}
const _hoisted_5 = { class: "mb-2 font-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_collapse_transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["stop"])),
          class: "mb-6 flex items-center text-xs text-white lg:hidden"
        }, [
          _createVNode(_component_biz_icon, {
            name: "chevron-right",
            class: "mr-2 h-2 w-1.5 rotate-180 fill-current"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.backLabel), 1)
        ], 512), [
          [_vShow, _ctx.expanded]
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        ref: "input",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchValue) = $event)),
        onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onKeydown: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"])),
        class: "peer -ml-4.5 -mb-px w-full border-b-2 border-transparent bg-transparent pl-7 pb-1 text-sm font-light font-light placeholder-current outline-none transition-colors focus:border-white lg:ml-0 lg:-mr-5 lg:pl-0 lg:pb-0 lg:text-4xl lg:focus:border-gray-500 focus:lg:text-gray-500",
        placeholder: _ctx.placeholder
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.searchValue]
      ]),
      _createVNode(_component_biz_icon, {
        class: "mb-2 h-4.5 w-4.5 fill-current lg:mb-0 lg:h-5 lg:w-5 peer-focus:lg:text-gray-500",
        name: "search"
      })
    ]),
    _createElementVNode("button", {
      class: "hidden lg:block lg:h-5 lg:w-5",
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
    }, [
      _createVNode(_component_biz_icon, {
        class: "h-full w-full fill-current",
        name: "close"
      })
    ]),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.popularLabel), 1),
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}