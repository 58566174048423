import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "container section-padding bg-black text-white lg:bg-transparent lg:text-gray-500" }
const _hoisted_2 = { class: "mb-9 flex items-center justify-start border-b pt-2 text-white lg:mb-24 lg:w-2/3 lg:flex-row-reverse lg:justify-between lg:border-gray-400 lg:text-gray-400" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col justify-between lg:mb-2 lg:flex-row"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "my-6 grid gap-y-4 font-light text-gray-400 lg:my-0 lg:grid-cols-3 lg:gap-x-4 lg:text-gray-400" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "container mt-16"
}
const _hoisted_12 = {
  key: 2,
  class: "relative divide-y divide-white divide-opacity-30 overflow-y-auto lg:divide-gray-400 lg:divide-opacity-30"
}
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "relative hidden w-40 flex-none overflow-hidden lg:block" }
const _hoisted_15 = { class: "h-0 bg-gray-100 pt-1/1" }
const _hoisted_16 = ["src", "alt"]
const _hoisted_17 = { class: "mr-4 lg:px-20" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "mb-2 pr-1.5 text-lg font-bold lg:text-xl" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "group ml-auto flex h-9 w-9 shrink-0 cursor-pointer items-center justify-center self-end border transition-colors group-hover:border-white group-hover:bg-white group-hover:text-gray-500 lg:self-center lg:border-gray-400 lg:group-hover:border-gray-500 lg:group-hover:bg-gray-500 lg:group-hover:text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_biz_icon, {
        class: "mb-2 h-4.5 w-4.5 fill-current lg:mb-0 lg:h-5 lg:w-5",
        name: "search"
      }),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
        onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.onSearch(false)), ["enter"])),
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        class: "-mb-px -ml-4.5 w-full border-b-2 border-transparent bg-transparent pl-7 pb-1 text-sm font-light placeholder-current outline-none transition-colors focus:border-white lg:ml-0 lg:-mr-5 lg:pl-0 lg:pb-0 lg:text-4xl lg:focus:border-gray-500",
        placeholder: _ctx.placeholder
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.searchValue]
      ])
    ]),
    (_ctx.result)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.result)
            ? (_openBlock(), _createElementBlock("b", _hoisted_5, _toDisplayString(_ctx.filter ? _ctx.result[_ctx.filter] : _ctx.result.total) + " " + _toDisplayString(_ctx.resultsLabel), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_6)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex cursor-pointer justify-between border-b border-current transition-colors lg:hover:text-gray-500", {
                        'text-white lg:text-gray-500': _ctx.filter === 'products',
                        'pointer-events-none opacity-50':
                            _ctx.result.products === 0 && _ctx.filter !== 'products'
                    }]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleFilter('products')))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.productsLabel), 1),
              (_ctx.result)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(" + _toDisplayString(_ctx.result.products) + ")", 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["flex cursor-pointer justify-between border-b border-current transition-colors lg:hover:text-gray-500", {
                        'text-white lg:text-gray-500': _ctx.filter === 'news',
                        'pointer-events-none opacity-50': _ctx.result.news === 0 && _ctx.filter !== 'news'
                    }]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleFilter('news')))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.pressLabel), 1),
              (_ctx.result)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, "(" + _toDisplayString(_ctx.result.news) + ")", 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["flex cursor-pointer justify-between border-b border-current transition-colors lg:hover:text-gray-500", {
                        'text-white lg:text-gray-500': _ctx.filter === 'services',
                        'pointer-events-none opacity-50':
                            _ctx.result.services === 0 && _ctx.filter !== 'services'
                    }]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleFilter('services')))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.serviceLabel), 1),
              (_ctx.result)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, "(" + _toDisplayString(_ctx.result.services) + ")", 1))
                : _createCommentVNode("", true)
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.results.length === 0 && _ctx.searchValue.length > 0 && _ctx.result)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.noResultsMessage), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result, r) => {
            return (_openBlock(), _createElementBlock("a", {
              key: `sr#${r}`,
              class: "group flex items-center py-8",
              href: result.link
            }, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  (result.image)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: result.image,
                        alt: result.imageAlt,
                        class: "absolute top-0 left-0 h-full w-full object-cover object-center"
                      }, null, 8, _hoisted_16))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                (result.kicker)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      innerHTML: result.kicker,
                      class: "mb-2 text-sm uppercase leading-5 tracking-wider lg:text-base lg:text-gray-400"
                    }, null, 8, _hoisted_18))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.removeHtmlTags(result.title)), 1),
                _createElementVNode("div", {
                  class: "pr-1.5 line-clamp-2 lg:text-gray-400",
                  innerHTML: result.text
                }, null, 8, _hoisted_20)
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_biz_icon, {
                  name: "chevron-right",
                  class: "h-3 w-3 shrink-0 fill-current group-hover:text-gray-500 lg:text-gray-400 lg:group-hover:text-white"
                })
              ])
            ], 8, _hoisted_13))
          }), 128)),
          _renderSlot(_ctx.$slots, "default")
        ]))
  ]))
}