import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.htmlStructures.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["container section-padding", { 'lg:grid lg:grid-cols-2 lg:gap-20': _ctx.htmlStructures.length > 1 }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.htmlStructures, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "prose-career mt-16 first:m-0 lg:prose-career-lg lg:m-0",
            key: index,
            innerHTML: item
          }, null, 8, _hoisted_1))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}