export default {
    mounted(element: HTMLElement, binding) {
        // if have an appear value start from the bottom and goes to the value passed
        if (binding.value.appear) {
            const scrollValue = binding.value.show ? binding.value.show : 120;
            element.style.top = '120%';
            document.addEventListener('scroll', () => {
                if (document.documentElement.scrollTop > scrollValue) {
                    element.style.top = binding.value.appear;
                } else {
                    element.style.top = '120%';
                }
            });
        }

        // if have a scrollTo value goes to element with that id
        element.addEventListener('click', () => {
            if (binding.value.scrollTo) {
                document.getElementById(binding.value.scrollTo).scrollIntoView();
            } else {
                document.documentElement.scrollTop = 0;
            }
        });
    }
};
