import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute origin-left -translate-y-full scale-75 text-gray-400 transition-transform" }
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative flex w-full items-center", [_ctx.searchInput ? 'text-gray-500' : 'text-gray-400']])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.searchInput ? _ctx.name : ' '), 1),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInput) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSearch && _ctx.toggleSearch(...args))),
      placeholder: _ctx.name,
      class: "form-input"
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.searchInput]
    ]),
    _createVNode(_component_biz_icon, {
      class: "absolute right-0 -z-10 ml-4 mb-6 h-4 w-4 shrink-0 fill-current",
      name: "search"
    })
  ], 2))
}