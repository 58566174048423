<template>
    <div class="flex justify-center space-x-2 py-8">
        <div
            class="h-2 w-2 animate-load rounded-full bg-gray-500 opacity-0"
            style="animation-delay: 0.9s"></div>
        <div
            class="h-2 w-2 animate-load rounded-full bg-gray-500 opacity-0"
            style="animation-delay: 0.6s"></div>
        <div
            class="h-2 w-2 animate-load rounded-full bg-gray-500 opacity-0"
            style="animation-delay: 0.3s"></div>
        <div class="h-2 w-2 animate-load rounded-full bg-gray-500 opacity-0"></div>
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner'
};
</script>

<style lang="scss" scoped></style>
