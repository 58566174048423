import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-index"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        "data-index": _ctx.index,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        class: _normalizeClass([
            _ctx.mousewheel ? 'cursor-ew-resize' : _ctx.enableClick ? 'cursor-pointer' : 'cursor-default'
        ])
      }, [
        _renderSlot(_ctx.$slots, "default", { active: _ctx.active })
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}