import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Transition as _Transition, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container relative" }
const _hoisted_2 = { class: "flex h-screen w-full items-center justify-center" }
const _hoisted_3 = { class: "h-full select-none py-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.allImage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "absolute inset-0 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPopUp && _ctx.openPopUp(...args)))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute bottom-0 right-0 flex h-12 w-12 cursor-pointer items-center justify-center", [_ctx.open ? 'hidden' : '', _ctx.mobileHide && !_ctx.open ? 'hidden md:flex' : '', _ctx.boxColor]]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openPopUp && _ctx.openPopUp(...args)))
    }, [
      _createVNode(_component_biz_icon, {
        name: "plus-thin",
        class: "h-8 w-8 fill-current"
      })
    ], 2),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _withDirectives(_createVNode(_Transition, {
        "enter-active-class": "duration-500 ease-out",
        "enter-from-class": "transform opacity-0",
        "enter-to-class": "opacity-100",
        "leave-active-class": "duration-500 ease-in",
        "leave-from-class": "opacity-100",
        "leave-to-class": "transform opacity-0",
        class: _normalizeClass([_ctx.open ? 'z-50' : '', "fixed left-0 top-0 h-screen w-screen bg-gray-500/90 lg:bg-gray-500/75"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "absolute top-0 right-0 cursor-pointer",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closePopUp && _ctx.closePopUp(...args)))
              }, [
                _createVNode(_component_biz_icon, {
                  name: "close",
                  class: "mt-5 mr-8 h-6 w-8 fill-white transition-colors lg:mr-0"
                })
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "default")
                ])
              ])
            ])
          ])
        ]),
        _: 3
      }, 8, ["class"]), [
        [_vShow, _ctx.open]
      ])
    ]))
  ], 64))
}