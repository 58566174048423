
import { defineComponent } from 'vue';
import { addToState, filterItems, fitSquaresInRect, getTwConfig, removeFromState } from '@/utils';
import BizIcon from '@/components/base/BizIcon.vue';
import { PRODUCTS_REST_ENDPOINT } from '@/bizerba';

const LIMIT = 4;
const CAT_GRID_LIMIT = 6;

export default defineComponent({
    components: { BizIcon },
    props: {
        uuid: String,
        image: String,
        headlineLabel: String,
        resultsHeadlineLabel: String,
        backLabel: String,
        nextLabel: String,
        showAllLabel: String
    },
    data() {
        return {
            ready: false,
            columns: null,
            filters: [],
            selectedCategories: [],
            step: 0,
            nextStepCategories: [],
            offset: 0,
            cellSize: 0,
            currentImage: null,
            result: null,
            twConfig: getTwConfig(),
            CAT_GRID_LIMIT,
            foodIndustryKeys: [
                'ready-meals-soups',
                'bakery-products',
                'animal-food',
                'confectionery-ice-cream',
                'pasta-rice',
                'snacks',
                'breakfast-muesli',
                'baby-food',
                'ingredients-sauces-seasonings',
                'supplements-tobacco',
                'drinks',
                'meat-sausages',
                'fish-seafood',
                'dairy-products',
                'fruit-vegetables'
            ],
            logisticsKeys: [
                'courier-express-parcel-postal',
                'warehousing',
                'e-commerce',
                'shipping'
            ],
            manufacturingKeys: [
                'building-materials-dry-bulk',
                'pharma',
                'chemical-petrochemical-pharmaceutical',
                'paints-varnishes',
                'electrical-products',
                'mechanical-engineering',
                'metalworking',
                'plant-engineering',
                'vehicle-manufacturing'
            ],
            retailKeys: [
                'bakery',
                'butcher',
                'milk-cheese',
                'fruit-vegetables',
                'supermarket',
                'unpacked-store',
                'deli'
            ],
            foodcraftsKeys: [
                'farm-shop',
                'market-stall',
                'deli',
                'unpacked-store',
                'fruit-vegetables',
                'smaller-grocery-stores',
                'milk-cheese',
                'butcher',
                'bakery'
            ],
            gastronomyKeys: ['kitchen-catering', 'restaurant-hotel']
        };
    },
    provide() {
        return {
            registerOverviewFilter: this.registerFilter,
            toggleOverviewFilter: () => undefined,
            overviewItems: []
        };
    },
    mounted() {
        this.getCategories();
    },
    methods: {
        registerFilter(filter) {
            if (filter.hero) {
                this.columns = filter;
            } else {
                this.filters.push(filter);
                this.selectedCategories.push([]);
            }
            if (
                this.$slots.default().filter(filterItems).length ===
                this.filters.length + (this.columns ? 1 : 0)
            ) {
                this.ready = true;
                this.loadHash();
            }
        },
        selectStep(step) {
            if (step < this.step) {
                for (let i = 0; i < this.selectedCategories.length; i++) {
                    if (i > step) {
                        this.selectedCategories[i] = [];
                    }
                }
            }
            this.step = step;
        },
        next() {
            if (this.step < this.filters.length) {
                this.step++;
            }
        },
        prev() {
            if (this.step > 0) {
                removeFromState(this.currentCategory.filterKey);
                for (let i = this.step; i < this.selectedCategories.length; i++) {
                    this.selectedCategories[i] = [];
                }
                this.step--;
            }
        },
        toggleCategory(key) {
            const index = this.selectedCategories[this.step].indexOf(key);
            if (index < 0) {
                // at the first step, is only possible to select one element
                if (this.step === 0) {
                    this.selectedCategories[0].forEach(element => {
                        removeFromState(this.currentCategory.filterKey, element);
                        this.selectedCategories[this.step].splice(0, 1);
                    });
                }
                addToState(this.currentCategory.filterKey, key);
                this.selectedCategories[this.step].push(key);
            } else {
                removeFromState(this.currentCategory.filterKey, key);
                this.selectedCategories[this.step].splice(index, 1);
            }
        },
        isCategorySelected(key) {
            return this.selectedCategories[this.step].some(x => x === key);
        },
        async getCategories() {
            if (this.step >= this.filters.length) {
                return;
            }
            let url = `${this.$contextPath}${PRODUCTS_REST_ENDPOINT}/${this.$siteName}/${this.$lang}/${this.uuid}/${this.step}`;
            if (this.filterString.length) {
                url += `?filter=${this.filterString}`;
            }
            try {
                const response = await fetch(url);
                this.nextStepCategories = (await response.json()).slice(0, 18);
            } catch (e) {
                console.error(e);
            }
            this.$nextTick(() => {
                if (this.currentCategories.length > CAT_GRID_LIMIT) {
                    this.calcGrid();
                }
            });
        },
        async loadAll(column) {
            const results = await this.getProductColumn(column, -1);
            this.result[results.key] = results.result;
        },
        getProducts() {
            Promise.all(this.columns.categories.map(x => this.getProductColumn(x))).then(
                results => {
                    this.result = {};
                    results.forEach(({ key, result }) => {
                        this.result[key] = result;
                    });
                }
            );
        },
        async getProductColumn(column, limit = LIMIT) {
            let url = `${this.$contextPath}${PRODUCTS_REST_ENDPOINT}/${this.$siteName}/${this.$lang}/${this.uuid}/products/${column.categoryId}?limit=${limit}&offset=${this.offset}`;
            if (this.filterString.length) {
                url += `&filter=${this.filterString}`;
            }
            try {
                const response = await fetch(url);
                const result = await response.json();
                return { key: column.categoryKey, result };
            } catch (e) {
                console.error(e);
                return null;
            }
        },
        calcGrid() {
            const rect: DOMRect = this.$refs.catContainer.getBoundingClientRect();
            this.cellSize =
                fitSquaresInRect(rect.width, rect.height, this.currentCategories.length) -
                (CAT_GRID_LIMIT + 2);
        },
        hoverCategory(category) {
            this.currentImage = category.image || null;
        },
        blurCategory() {
            if (this.selectedCategories[this.step].length) {
                const categoryKey =
                    this.selectedCategories[this.step][
                        this.selectedCategories[this.step].length - 1
                    ];
                const category = this.currentCategories.find(x => x.categoryKey === categoryKey);
                this.currentImage = category.image || null;
            } else {
                this.currentImage = null;
            }
        },
        breakpointSize(name: string): number {
            if (Object.prototype.hasOwnProperty.call(this.twConfig.theme.screens, name)) {
                return parseInt(this.twConfig.theme.screens[name]);
            }
            return -1;
        },
        loadHash() {
            const hash = location.hash.replace(/^#/, '');
            const filters = hash
                .split('&')
                .filter(x => x.includes(':'))
                .map(x => x.split(':'))
                .filter(x => x.length === 2);
            if (
                this.filters.length >= filters.length &&
                filters.every((x, i) => this.filters[i].filterKey === x[0])
            ) {
                filters.forEach((x, i) => {
                    this.selectedCategories[i] = x[1].split(',');
                });
                this.selectStep(filters.length);
            }
        }
    },
    computed: {
        filterString() {
            return this.selectedCategories
                .map((x, i) => (x.length ? `${this.filters[i].filterKey}:${x.join(',')}` : ''))
                .filter(x => x.length)
                .join(';');
        },
        currentCategory() {
            if (this.filters.length > this.step) {
                return this.filters[this.step];
            }
            return this.filters.length ? this.filters[this.filters.length - 1] : null;
        },
        currentCategories() {
            if (this.currentCategory) {
                let categories = this.currentCategory.categories;
                if (this.step === 1) {
                    switch (this.selectedCategories[0][0]) {
                        case 'food-industry':
                            categories = this.currentCategory.categories.filter(category =>
                                this.foodIndustryKeys.includes(category.categoryKey)
                            );
                            break;
                        case 'logistics':
                            categories = this.currentCategory.categories.filter(category =>
                                this.logisticsKeys.includes(category.categoryKey)
                            );
                            break;
                        case 'manufacturing-process-industry':
                            categories = this.currentCategory.categories.filter(category =>
                                this.manufacturingKeys.includes(category.categoryKey)
                            );
                            break;
                        case 'retail':
                            categories = this.currentCategory.categories.filter(category =>
                                this.retailKeys.includes(category.categoryKey)
                            );
                            break;
                        case 'food-crafts':
                            categories = this.currentCategory.categories.filter(category =>
                                this.foodcraftsKeys.includes(category.categoryKey)
                            );
                            break;
                        case 'gastronomy':
                            categories = this.currentCategory.categories.filter(category =>
                                this.gastronomyKeys.includes(category.categoryKey)
                            );
                            break;
                    }
                }
                return this.step >= 0
                    ? categories.filter(c => this.nextStepCategories.some(x => x === c.categoryId))
                    : categories;
            }
            return [];
        },
        gridSize() {
            if (this.currentCategories.length <= 6) {
                return {};
            }
            return window.innerWidth >= this.breakpointSize('lg')
                ? {
                      width: `${this.cellSize}px`
                  }
                : {};
        }
    },
    watch: {
        step() {
            if (this.step >= 0) {
                this.step === this.filters.length ? this.getProducts() : this.getCategories();
            }
            if (this.step > 1) {
                this.$refs.steps.scrollLeft = this.$refs.steps.scrollWidth;
            }
        }
    }
});
