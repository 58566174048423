import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container h-full" }
const _hoisted_2 = { class: "container-screen-r container-wide-l pl-container-wide pr-container-wide -mx-8 h-full overflow-y-auto overflow-x-hidden bg-white px-4 py-6 lg:overflow-hidden lg:py-20" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!
  const _component_campaign_popup = _resolveComponent("campaign-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default", {
      changeClickedIndex: _ctx.changeClickedIndex,
      id: _ctx.id
    }),
    _createVNode(_component_campaign_popup, { id: _ctx.id }, {
      default: _withCtx(({ close }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              class: "z-10 mb-12 flex items-center font-bold text-gray-400 lg:mb-0",
              onClick: close
            }, [
              _createVNode(_component_biz_icon, {
                class: "mr-3 h-3 w-3 shrink-0 rotate-180 transform fill-current",
                name: "chevron-right"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.backLabel), 1)
            ], 8, _hoisted_3),
            _renderSlot(_ctx.$slots, "popup", { clickedIndex: _ctx.clickedIndex })
          ])
        ])
      ]),
      _: 3
    }, 8, ["id"])
  ], 64))
}