
import { defineComponent } from 'vue';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    components: { BizIcon },
    props: {
        boxColor: {
            type: String,
            required: false
        },
        mobileHide: {
            type: Boolean,
            default: false
        },
        allImage: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            open: false,
            slideTransform: []
        };
    },
    methods: {
        openPopUp() {
            // block scroll of page
            document.body.classList.toggle('overflow-hidden', true);
            this.open = true;
        },
        closePopUp() {
            document.body.classList.toggle('overflow-hidden', false);
            this.open = false;
        }
    }
});
