
import { defineComponent } from 'vue';
import OverviewCategory from '@/components/overview/OverviewCategory.vue';
import { filterItems } from '@/utils';

export default defineComponent({
    props: {
        name: String,
        index: Number,
        filterKey: String,
        hero: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            categories: [],
            selectedCategory: '',
            registered: false
        };
    },
    provide() {
        return {
            registerOverviewCategory: this.registerCategory
        };
    },
    mounted() {
        this.registerOverviewFilter(this);
    },
    inject: {
        registerOverviewFilter: {},
        toggleOverviewFilter: {
            default: () => undefined
        },
        overviewItems: {
            default: []
        }
    },
    methods: {
        registerCategory(category: InstanceType<typeof OverviewCategory>) {
            this.categories.push(category);
            if (this.$slots.default().filter(filterItems).length === this.categories.length) {
                this.registered = true;
            }
        },
        toggleCategoryByKey(key) {
            const cat = this.categories.find(c => c.categoryKey === key);
            if (!cat) {
                return;
            }
            if (this.hasItems(cat.categoryId) && cat.categoryId !== this.selectedCategory) {
                this.$refs.dropdown.setValue(cat.categoryId);
            }
        },
        toggleCategory({ value: id }) {
            const oldCat = this.selectedCategory;
            this.selectedCategory = this.selectedCategory === id ? '' : id;
            const category = this.categories.find(x => x.categoryId === this.selectedCategory);
            this.toggleOverviewFilter(id, oldCat, {
                filter: this.filterKey,
                category: category ? category.categoryKey : ''
            });
        },
        hasItems(categoryId: string): boolean {
            return this.overviewItems
                .filter(x => x.show)
                .some(item => item.categories.map(c => c.categoryId).includes(categoryId));
        },
        existItems(categoryId: string): boolean {
            return this.overviewItems.some(item =>
                item.categories.map(c => c.categoryId).includes(categoryId)
            );
        }
    },
    watch: {}
});
