import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "prose hyphens-auto lg:grid lg:grid-cols-2 lg:gap-x-8" }
const _hoisted_2 = { class: "initial-letter" }
const _hoisted_3 = { class: "lg:inline lg:hidden" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "content",
      class: _normalizeClass({ 'prose lg:prose-columns lg:col-span-2': _ctx.autoCols })
    }, [
      (_ctx.text.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.initial), 1),
            _createElementVNode("span", {
              ref: "leftCol",
              class: "hidden lg:inline"
            }, _toDisplayString(_ctx.leftColText), 513),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.fullText), 1)
          ], 64))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ], 2),
    (!_ctx.autoCols)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", { ref: "rightCol" }, _toDisplayString(_ctx.rightColText), 513)
        ]))
      : _createCommentVNode("", true)
  ]))
}