
import { defineComponent } from 'vue';
import { filterItems } from '@/utils';

export default defineComponent({
    props: {
        title: String,
        image: String,
        link: String,
        target: String
    },
    data() {
        return {
            ready: false,
            subItems: []
        };
    },
    inject: ['registerProductCategory'],
    provide() {
        return {
            registerProductSubCategory: this.register
        };
    },
    mounted() {
        this.registerProductCategory(this);
    },
    methods: {
        register(item) {
            this.subItems.push(item);
            if (this.$slots.default().filter(filterItems).length === this.subItems.length) {
                this.ready = true;
            }
        }
    },
    computed: {},
    watch: {}
});
