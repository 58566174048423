import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative mb-8" }
const _hoisted_2 = ["required", "id", "name", "value"]
const _hoisted_3 = {
  key: 0,
  class: "form-validate !-bottom-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_biz_dropdown = _resolveComponent("biz-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "hidden",
      required: _ctx.required,
      id: _ctx.id,
      name: _ctx.name,
      value: _ctx.value
    }, null, 8, _hoisted_2),
    _createVNode(_component_biz_dropdown, {
      title: _ctx.label,
      onSelect: _ctx.toggleInput,
      contrast: _ctx.contrast,
      "contrast-mobile": _ctx.contrastMobile,
      "red-border": _ctx.notvalid && _ctx.required && (_ctx.value === null || _ctx.value === ''),
      ref: "dropdown"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_dropdown_item, {
            key: item.id,
            title: item.value,
            value: item.id
          }, null, 8, ["title", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["title", "onSelect", "contrast", "contrast-mobile", "red-border"]),
    (_ctx.notvalid && _ctx.required && (_ctx.value === null || _ctx.value === ''))
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.validateText), 1))
      : _createCommentVNode("", true)
  ]))
}