import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "hidden lg:flex" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["mr-9 mb-6 w-full cursor-pointer border-b-2 pb-2 text-xl font-bold last:mr-0", [
                    !tab.active ? 'opacity-30' : '',
                    _ctx.contrast ? 'border-white' : 'border-black'
                ]]),
          key: index,
          onClick: ($event: any) => (_ctx.activate(tab))
        }, _toDisplayString(tab.title), 11, _hoisted_2))
      }), 128))
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}