
import { defineComponent } from 'vue';

export default defineComponent({
    props: {},
    data() {
        return {
            open: false
        };
    },
    inject: ['registerSiteNavItem', 'clickSiteNavItem'],
    mounted() {
        this.registerSiteNavItem(this);
    },
    methods: {
        onClick() {
            this.clickSiteNavItem(this);
        }
    },
    computed: {},
    watch: {}
});
