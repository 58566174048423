import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group fixed bottom-0 left-0 z-40 w-full lg:bottom-auto lg:left-auto lg:top-1/2 lg:right-0 lg:w-auto lg:-translate-y-1/2" }
const _hoisted_2 = { class: "h-14 w-14 shrink-0 bg-gray-500 p-4 lg:w-full" }
const _hoisted_3 = { class: "lg:text-vertical flex flex-1 items-center justify-between whitespace-nowrap bg-white p-4 transition-opacity lg:group-hover:opacity-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex w-full shadow-lg lg:ml-auto lg:w-14 lg:flex-col",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_biz_icon, {
          name: "link-light",
          class: "h-full w-full fill-white"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.moreContentLabel) + " ", 1),
        _createVNode(_component_biz_icon, {
          name: "chevron-right",
          class: _normalizeClass(["h-3 w-3 fill-current lg:hidden", [_ctx.open ? 'rotate-90' : '-rotate-90']])
        }, null, 8, ["class"])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([[_ctx.open ? 'max-h-screen' : 'max-h-0'], "transition-all duration-500 lg:transition-none"])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}