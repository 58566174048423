
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        fullTime: {
            type: Number,
            default: 5000,
            required: false
        },
        actualIndex: {
            type: Number,
            required: true
        },
        slide: {
            type: Number,
            required: true
        },
        contrast: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            timerCount: null
        };
    },
    methods: {},
    watch: {
        timerCount: {
            handler(value) {
                if (value < this.fullTime && this.actualIndex - 1 === this.slide) {
                    setTimeout(() => {
                        this.timerCount = this.timerCount + 50;
                    }, 50);
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        },
        actualIndex() {
            if (this.actualIndex - 1 === this.slide) this.timerCount = 0;
        }
    },
    computed: {
        percentageCount() {
            if (this.actualIndex - 1 < this.slide || this.slide < this.actualIndex - 1) {
                return { width: `0%` };
            }
            return { width: `${(this.timerCount * 100) / this.fullTime}%` };
        }
    }
});
