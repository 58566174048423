
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

export default defineComponent({
    components: { CollapseTransition },
    props: {
        hero: {
            type: Boolean,
            default: false
        },
        color: {
            type: Boolean,
            default: false
        },
        dropdown: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            open: false
        };
    }
});
