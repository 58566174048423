import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_biz_dropdown = _resolveComponent("biz-dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_biz_dropdown, {
      title: _ctx.name,
      onSelect: _ctx.toggleCategory,
      ref: "dropdown"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (cat) => {
          return (_openBlock(), _createBlock(_component_dropdown_item, {
            key: cat.categoryId,
            title: cat.name,
            disabled: !_ctx.hasItems(cat.categoryId),
            hidden: !_ctx.existItems(cat.categoryId),
            value: cat.categoryId
          }, null, 8, ["title", "disabled", "hidden", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["title", "onSelect"]),
    (!_ctx.registered)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}