import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.filterClasses)
    }, [
      _renderSlot(_ctx.$slots, "filters", { results: _ctx.total })
    ], 2),
    _renderSlot(_ctx.$slots, "default"),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(_ctx.noResultsClasses)
    }, [
      _renderSlot(_ctx.$slots, "noresults")
    ], 2), [
      [_vShow, !_ctx.total]
    ])
  ]))
}