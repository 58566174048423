import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.submitted)
      ? _renderSlot(_ctx.$slots, "default", {
          key: 0,
          submit: _ctx.submit,
          notvalid: _ctx.notvalid,
          selectFile: _ctx.selectFile,
          removeFile: _ctx.removeFile,
          files: _ctx.files,
          captchaSolved: _ctx.captchaSolved,
          onCaptchaSolved: _ctx.onCaptchaSolved,
          filesizeError: _ctx.fileSizeError,
          fileCountError: _ctx.fileCountError
        })
      : _createCommentVNode("", true),
    (_ctx.submitted && _ctx.success)
      ? _renderSlot(_ctx.$slots, "success", { key: 1 })
      : _createCommentVNode("", true),
    (_ctx.submitted && !_ctx.success)
      ? _renderSlot(_ctx.$slots, "error", { key: 2 })
      : _createCommentVNode("", true)
  ]))
}