import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target"]
const _hoisted_2 = { class: "flex flex-col justify-center pr-6 lg:flex-auto lg:justify-end lg:pr-0" }
const _hoisted_3 = { class: "flex flex-1 items-center justify-end lg:hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!

  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass([[
            { 'bg-opacity-80': _ctx.active },
            { 'bg-opacity-50': !_ctx.active },
            _ctx.contrast ? 'bg-black active-slide:bg-opacity-80' : 'bg-white active-slide:bg-opacity-75'
        ], "relative inline-flex h-full w-full shrink-0 px-8 py-6 transition-colors duration-300 lg:static lg:p-8"]),
    href: _ctx.link,
    target: _ctx.target,
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hover && _ctx.hover(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.leave && _ctx.leave(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([[_ctx.active || _ctx.showImage ? 'opacity-100' : 'lg:opacity-0'], "absolute top-0 left-0 -z-1 h-full w-full transition-opacity duration-1000"])
    }, [
      _renderSlot(_ctx.$slots, "image")
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", { active: _ctx.active })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_biz_icon, {
        class: "h-3 w-1.5 shrink-0 fill-current",
        name: "chevron-right"
      })
    ])
  ], 42, _hoisted_1))
}