import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  class: "hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("a", {
    href: _ctx.link,
    class: _normalizeClass(["block", _ctx.classes])
  }, [
    _renderSlot(_ctx.$slots, "default", {
      index: _ctx.index,
      total: _ctx.total
    }),
    (!_ctx.categoriesRegistered)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "categories")
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1)), [
    [_vShow, _ctx.show]
  ])
}