
import { defineComponent, h } from 'vue';

export default defineComponent({
    props: {
        link: String,
        title: String,
        target: String,
        subItemsSize: Number,
        isSubItem: Boolean
    },
    data() {
        return {
            ready: false,
            subItems: []
        };
    },
    inject: {
        registerNavItem: {
            from: 'registerNavItem',
            default: () => null
        },
        registerProductSubCategory: {
            from: 'registerProductSubCategory',
            default: () => null
        },
        registerSubItem: {
            from: 'registerSubItem',
            default: () => null
        }
    },
    provide() {
        return {
            registerSubItem: this.register
        };
    },
    mounted() {
        if (this.registerProductSubCategory) {
            this.registerProductSubCategory(this);
        } else if (this.isSubItem) {
            this.registerSubItem(this);
        } else {
            this.registerNavItem(this);
        }
    },
    render() {
        return h(
            this.link ? 'a' : 'div',
            {
                class: 'group relative flex h-full items-center font-light',
                href: this.link,
                target: this.target
            },
            [
                h('span', this.title),
                h('span', {
                    class: 'absolute bottom-0 left-0 w-full scale-0 transition-transform duration-300 lg:border-b-2 lg:border-gray-500 lg:group-hover:scale-100'
                }),
                h('span', { class: 'invisible' }, this.$slots.default ? this.$slots.default() : [])
            ]
        );
    },
    methods: {
        register(item) {
            this.subItems.push(item);
            if (this.subItemsSize === this.subItems.length) {
                this.ready = true;
            }
        }
    },
    computed: {},
    watch: {}
});
