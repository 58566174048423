
/* eslint-disable prettier/prettier */
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        htmlDescription: String,
    },
    computed: {
        htmlStructures(): string[] {
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.htmlDescription, 'text/html');

            // Clean all styles
            this.cleanHTML(doc.body);

            // Extract and filter out null values
            return this.extractStructures(doc.body).filter(structure => structure !== null);
        },
    },
    methods: {
        cleanHTML(node: HTMLElement): void {
            if (node instanceof HTMLElement) {
                // Remove class and style attributes from this node
                node.removeAttribute('class');
                node.removeAttribute('style');
                
                // Clean all children nodes
                for (let i = 0; i < node.children.length; i++) {
                    this.cleanHTML(node.children[i] as HTMLElement);
                }
            }
        },
        extractStructures(node: HTMLElement): string[] {
            let structures: string[] = [];
            
            // If only one h2 return to print
            if (node.querySelectorAll('h2').length === 1) {
                structures.push(node.innerHTML);
                return structures;
            } else if (node.querySelectorAll('h2').length === 0) {
                return [];
            }
            // If more than one h2 divide div
            for (let i = 0; i < node.children.length; i++) {
                const child = node.children[i] as HTMLElement;
                if (child.tagName.toLowerCase() === 'div') {
                    structures = structures.concat(this.extractStructures(child));
                }
            }
            return structures;
        }
    },
});
