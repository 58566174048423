export default {
    mounted(element: HTMLVideoElement) {
        // check if is a mobile device
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );

        if (isMobile) {
            const observerConfig = {
                root: null,
                rootMargin: '0px',
                // percentage of the video visible
                threshold: 0.5
            };

            // handle intersecting of video with observer entry
            const handleIntersection = (entries: IntersectionObserverEntry[]) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        // play video when visible
                        element.play();
                    } else {
                        // pause video when not visible
                        element.pause();
                    }
                });
            };
            const observer = new IntersectionObserver(handleIntersection, observerConfig);
            observer.observe(element);
        } else {
            // create event listener
            element.addEventListener('mouseover', () => {
                element.play();
            });
            element.addEventListener('mouseout', () => {
                element.pause();
            });
        }
    }
};
