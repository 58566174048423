
import { defineComponent } from 'vue';
import OverviewCategory from '@/components/overview/OverviewCategory.vue';

export default defineComponent({
    props: {
        link: String,
        dynamicClasses: Object,
        content: String
    },
    data() {
        return {
            index: -1,
            total: 0,
            show: true,
            categories: [],
            categoriesRegistered: false
        };
    },
    provide() {
        return {
            registerOverviewCategory: this.registerCategory
        };
    },
    mounted() {
        this.registerOverviewItem(this);
    },
    inject: ['registerOverviewItem'],
    methods: {
        registerCategory(category: InstanceType<typeof OverviewCategory>) {
            this.categories.push(category);
        },
        checkFilters(categories: string[], searchValue: string) {
            this.show =
                categories.every(cat => this.categories.map(x => x.categoryId).includes(cat)) &&
                (searchValue.length < 3 ||
                    this.content.toLowerCase().includes(searchValue.toLowerCase()));
        }
    },
    computed: {
        classes(): { [key: string]: boolean } {
            if (this.dynamicClasses) {
                try {
                    const entries: [string, string][] = Object.entries(this.dynamicClasses);
                    return entries.reduce((res, val: string[]) => {
                        // eslint-disable-next-line no-eval
                        res[val[0]] = eval(
                            val[1].replace(/i/g, this.index).replace(/t/g, this.total)
                        ) as boolean;
                        return res;
                    }, {} as { [key: string]: boolean });
                } catch (e) {
                    console.error('error during class generation', e);
                }
            }
            return {};
        }
    },
    watch: {}
});
