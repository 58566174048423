
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        name: String,
        icon: String,
        image: String,
        categoryId: String,
        categoryKey: String
    },
    data() {
        return {
            active: false,
            disabled: false,
            hidden: false
        };
    },
    mounted() {
        this.registerOverviewCategory(this);
    },
    inject: ['registerOverviewCategory']
});
