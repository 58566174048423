import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "font-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["read-more mb-6 md:mb-8", { inactive: _ctx.collapsed }])
  }, [
    _withDirectives(_createVNode(_component_collapse_transition, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _: 3
    }, 512), [
      [_vShow, !_ctx.collapsed]
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["mr-5 h-px flex-1 bg-current", _ctx.showColor ? 'bg-' + _ctx.showColor : 'bg-current'])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["cursor-pointer font-bold", _ctx.showColor ? 'text-' + _ctx.showColor : 'text-current']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
      }, [
        _createTextVNode(_toDisplayString(_ctx.collapsed ? _ctx.showMoreLabel : _ctx.showLessLabel) + " ", 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.collapsed ? '+' : '–'), 1)
      ], 2)
    ])
  ], 2))
}