
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

export default defineComponent({
    components: { CollapseTransition },
    props: {
        showMoreLabel: {
            type: String,
            required: true
        },
        showLessLabel: {
            type: String,
            required: true
        },
        showColor: {
            type: String
        }
    },
    data() {
        return {
            collapsed: true
        };
    }
});
