
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import { gsap } from 'gsap';
import { getTwConfig } from '@/utils';
import BizIcon from '@/components/base/BizIcon.vue';

const punycode = require('punycode/');

export default defineComponent({
    components: { BizIcon, CollapseTransition },
    props: {
        placeholder: {
            type: String,
            required: true
        },
        backLabel: {
            type: String,
            required: true
        },
        searchLink: {
            type: String,
            required: true
        },
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    inject: ['popularLabel'],
    data() {
        return {
            searchValue: '',
            twConfig: getTwConfig(),
            anim: null,
            expanded: false,
            punycode
        };
    },
    beforeUnmount() {
        this.close();
    },
    methods: {
        search() {
            try {
                const url = new URL(this.searchLink.replace(/^\//, ''), location.origin);
                url.searchParams.set('query', this.sanitizedInput);
                window.location.href = url.href;
            } catch (e) {
                console.error(e);
            }
        },
        onFocus() {
            if (window.innerWidth < parseInt(this.twConfig.theme.screens.lg)) {
                this.anim = gsap.to(this.$el, 0.3, { top: 64 });
                setTimeout(() => {
                    this.expanded = true;
                }, 150);
            }
        },
        close() {
            if (this.anim) {
                this.expanded = false;
                this.anim.reverse().then(() => {
                    this.anim.kill();
                    this.anim = null;
                    this.$el.style = '';
                });
            }
        }
    },
    computed: {
        sanitizedInput() {
            return punycode.toASCII(this.searchValue.trim());
        }
    },
    watch: {
        isOpen() {
            if (this.isOpen && this.$refs.input) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
        }
    }
});
