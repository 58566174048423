
import { defineComponent } from 'vue';
import BizIcon from '@/components/base/BizIcon.vue';

export default defineComponent({
    components: { BizIcon },
    props: {
        link: String,
        target: String,
        contrast: {
            type: Boolean,
            default: false
        }
    },
    inject: ['registerHeroTextMediaColumnsItem', 'hoverHeroTextMediaColumnsItem'],
    data() {
        return {
            active: false,
            showImage: false,
            index: -1
        };
    },
    mounted() {
        this.index = this.registerHeroTextMediaColumnsItem(this);
    },
    methods: {
        hover() {
            this.hoverHeroTextMediaColumnsItem(this.index);
        },
        leave() {
            this.hoverHeroTextMediaColumnsItem(this.index, false);
        }
    },
    computed: {},
    watch: {}
});
