
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import BizSearchInput from '@/components/navigation/BizSearchInput.vue';
import BizIcon from '@/components/base/BizIcon.vue';
import ProductNavigation from '@/components/navigation/ProductNavigation.vue';

export default defineComponent({
    components: { BizSearchInput, ProductNavigation, BizIcon, CollapseTransition },
    props: {
        overviewLabel: String,
        toOverviewLabel: String,
        popularLabel: String,
        backLabel: String,
        site: String,
        searchPlaceholder: String,
        homeLink: String,
        searchLink: String,
        searchTeasersJson: String,
        isHome: Boolean,
        langNav: Array
    },
    data() {
        return {
            items: [],
            products: [],
            openItem: null,
            openSubItem: null,
            openSubSubItem: null,
            isMenuOpen: false,
            isMobileOpen: false,
            siteNavOpen: false,
            productsOpen: false,
            showNavi: true,
            isSearchOpen: false,
            isTop: true,
            lastScrollPos: 0,
            isClosing: false,
            showItemsArrow: false,
            showSubItemsArrow: false
        };
    },
    mounted() {
        document.addEventListener('click', this.onOutsideClick);
        document.addEventListener('scroll', this.onScroll, { passive: false });
        window.addEventListener('hashchange', this.closeMenu);
        window.addEventListener('resize', this.checkItemsScrollbar);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.onOutsideClick);
        document.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('hashchange', this.closeMenu);
        window.removeEventListener('resize', this.checkItemsScrollbar);
    },
    provide() {
        return {
            registerMainNavItem: this.register,
            hoverMainNavItem: this.hoverItem,
            clickMainNavItem: this.clickItem,
            hoverNavItem: this.openSubMenu,
            registerProductCategory: this.registerProductCategory,
            popularLabel: this.popularLabel
        };
    },
    methods: {
        register(item) {
            this.items.push(item);
        },
        registerProductCategory(category) {
            this.products.push(category);
        },
        toggleMenu(item) {
            if (this.isMenuOpen) {
                this.closeMenu();
            } else {
                this.openMenu(item);
            }
        },
        hoverItem(item) {
            this.isSearchOpen = false;
            if (this.isMenuOpen || this.siteNavOpen) {
                this.openMenu(item);
            }
        },
        clickItem(item) {
            if (this.isMobileOpen) {
                this.openItem = item;
                this.productsOpen = item.cards;
            } else {
                this.toggleMenu(item);
            }
        },
        openMenu(item) {
            this.isClosing = false;
            this.openItem = item;
            this.isMenuOpen = true;
            this.siteNavOpen = false;
            this.productsOpen = item.cards;
            this.openSubSubItem = null;
            if (this.productsOpen && item.items.length) {
                this.openSubItem = item.items[0];
            }
        },
        openSubMenu(item) {
            if (this.openSubSubItem === item) {
                this.openSubSubItem = null;
            } else {
                this.openSubSubItem = item;
            }
        },
        back() {
            this.openItem = null;
        },
        closeMenu() {
            this.isClosing = this.transparent;
            this.isMenuOpen = false;
            this.productsOpen = false;
            this.openSubItem = null;
            this.siteNavOpen = false;
            this.isMobileOpen = false;
            this.openItem = null;
            this.showNavi = true;
            this.isSearchOpen = false;
        },
        closeSiteNav() {
            this.siteNavOpen = false;
        },
        openSiteNav(click = false) {
            if ((click || (this.isMenuOpen && !this.isMobileOpen)) && !this.siteNavOpen) {
                if (!this.isMobileOpen) {
                    this.closeMenu();
                }
                this.siteNavOpen = true;
            } else if (click && this.siteNavOpen) {
                this.closeMenu();
            }
        },
        toggleMobile() {
            if (this.isMobileOpen) {
                this.closeMenu();
            } else {
                this.isClosing = false;
                this.isMobileOpen = true;
                this.isMenuOpen = true;
            }
        },
        onOutsideClick(evt) {
            let targetElement = evt.target;

            do {
                if (targetElement === this.$el) {
                    return;
                }
                targetElement = targetElement.parentNode;
            } while (targetElement);
            this.closeMenu();
        },
        checkItemsScrollbar() {
            const scrollItemsElement = this.$refs.scrollItems;
            if (scrollItemsElement) {
                this.showItemsArrow =
                    scrollItemsElement.scrollHeight > scrollItemsElement.clientHeight &&
                    scrollItemsElement.scrollTop + scrollItemsElement.clientHeight + 0.5 !==
                        scrollItemsElement.scrollHeight;
            }
            const scrollSubItemsElement = this.$refs.scrollSubItems;
            if (scrollItemsElement) {
                this.showSubItemsArrow =
                    scrollSubItemsElement.scrollHeight > scrollSubItemsElement.clientHeight &&
                    scrollSubItemsElement.scrollTop + scrollSubItemsElement.clientHeight + 0.5 !==
                        scrollSubItemsElement.scrollHeight;
            }
        },
        onScroll(event: Event) {
            if (this.editMode) {
                return;
            }

            if (this.isOpen || this.showSearch) {
                event.preventDefault();
                return;
            }
            const scrollPos = Math.max(document.scrollingElement.scrollTop, 0);
            this.isTop = scrollPos === 0;
            this.showNavi = scrollPos <= this.lastScrollPos;
            this.lastScrollPos = scrollPos;
            this.isClosing = false;
        },
        hoverSearch() {
            if (this.isMenuOpen) {
                this.productsOpen = false;
                this.openSubItem = null;
                this.siteNavOpen = false;
                this.isMobileOpen = false;
                this.openItem = null;
                this.isSearchOpen = true;
            }
        },
        toggleSearch() {
            this.siteNavOpen = false;
            this.isMenuOpen = !this.isMenuOpen;
            this.isSearchOpen = this.isMenuOpen;
        },
        removeHtmlTags(text: string) {
            return text
                .replace(/&amp;/g, '&')
                .replace(/AMP;/g, '')
                .replace(/&lt;br\s?\/?&gt;/g, ' ')
                .replace(/shy;/g, '')
                .replace(/<br\s?\/?>/g, ' ');
        },
        createHtmlTags(text: string) {
            return text.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        }
    },
    computed: {
        subItems() {
            if (this.openItem && this.isMobileOpen && this.productsOpen) {
                return this.openItem.items && this.openItem.items.length
                    ? this.openItem.items[0].items
                    : [];
            }
            if (this.openItem) {
                return this.openItem.items;
            }
            return [];
        },
        transparent() {
            return this.isHome && this.isTop;
        },
        searchTeasers() {
            return JSON.parse(this.searchTeasersJson);
        }
    },
    watch: {
        isMenuOpen(open) {
            document.body.classList.toggle('overflow-hidden', open);
        },
        openItem() {
            this.items.forEach(item => {
                item.active = this.openItem ? this.openItem.title === item.title : false;
            });
            setTimeout(() => {
                this.checkItemsScrollbar();
            }, 10);
        }
    }
});
