let onScroll;

/**
 * Directive to trigger a number count-up animation when an element is scrolled in view
 * Usage:
 * 1. set v-count directive on parent element only containing a number that should be scrolled into view
 *
 * Note: "in view" means the top of the element is at least at the top of the screen
 *
 * TODO: try with intersection observer with a ratio of 1
 */
export default {
    mounted(element: HTMLElement) {
        const fixElements: NodeList = element.querySelectorAll('[data-count]');

        onScroll = () => {
            // data-visible attribute on element indicates if it is currently scrolled into view
            const inView = element.hasAttribute('data-visible');
            const rect = element.getBoundingClientRect();
            if ((inView && rect.top > window.innerHeight) || rect.bottom < 0) {
                // element leaves view
                delete element.dataset.visible; // remove visible flag
            } else if (
                !inView &&
                rect.top < window.innerHeight &&
                Math.abs(rect.top) < rect.height
            ) {
                // element comes into view (top of element reaches bottom of screen or bottom of element comes in from the top)
                element.dataset.visible = ''; // set visible flag
                fixElements.forEach((el: HTMLElement) => {
                    if (
                        Object.prototype.hasOwnProperty.call(el.dataset, 'count') &&
                        !isNaN(parseInt(el.dataset.count))
                    ) {
                        // trigger animation
                        animateCountUp(el);
                    }
                });
            }
        };
        document.addEventListener('scroll', onScroll);
    },
    beforeUnmount() {
        // cleanup
        document.removeEventListener('scroll', onScroll);
    }
};

// How long you want the animation to take, in ms
const animationDuration = 2000;
// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round(animationDuration / frameDuration);
// An ease-out function that slows the count as it progresses
const easeOutQuad = t => t * (2 - t);

// The animation function, which takes an Element
const animateCountUp = el => {
    if (Object.prototype.hasOwnProperty.call(el.dataset, 'animating')) {
        return;
    }
    let frame = 0;
    const countTo = parseInt(el.dataset.count, 10);
    // Start the animation running 60 times per second
    const counter = setInterval(() => {
        frame++;
        // Calculate our progress as a value between 0 and 1
        // Pass that value to our easing function to get our
        // progress on a curve
        const progress = easeOutQuad(frame / totalFrames);
        // Use the progress value to calculate the current count
        const currentCount = Math.round(countTo * progress);

        // If the current count has changed, update the element
        if (parseInt(el.innerHTML, 10) !== currentCount) {
            el.innerHTML = currentCount;
        }

        // If we’ve reached our last frame, stop the animation
        if (frame === totalFrames) {
            clearInterval(counter);
            delete el.dataset.animating;
        }
    }, frameDuration);
};
