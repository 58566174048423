
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        items: {
            type: Array
        },
        contrast: {
            type: Boolean,
            default: false
        },
        contrastMobile: {
            type: Boolean,
            default: false
        },
        notvalid: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: false
        },
        name: String,
        label: String,
        validateText: String,
        id: String
    },
    data() {
        return {
            value: '',
            index: -1
        };
    },
    methods: {
        toggleInput({ value, index }) {
            if (this.value === value && this.index === index) {
                this.index = -1;
                this.value = '';
            } else {
                this.index = index;
                this.value = value;
            }
        }
    }
});
