import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "-ml-4 flex flex-wrap items-start lg:ml-0 lg:items-center" }
const _hoisted_2 = { class: "ml-4 -mb-4 mr-4 lg:ml-0 lg:mb-0 lg:mr-0 lg:basis-0" }
const _hoisted_3 = { class: "inline-block border-t-2 border-t border-current pb-3 pt-4 font-bold" }
const _hoisted_4 = { class: "-mt-4 flex flex-1 flex-wrap items-start lg:items-center" }
const _hoisted_5 = ["title", "id", "data-type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_biz_icon = _resolveComponent("biz-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.share), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon) => {
        return (_openBlock(), _createElementBlock("div", {
          key: icon,
          ref_for: true,
          ref: "shareItems",
          title: icon,
          id: 'tr-share-' + icon,
          "data-type": icon,
          class: _normalizeClass([[
                    _ctx.contrast
                        ? 'text-white hover:bg-white hover:text-gray-500'
                        : 'text-gray-500 hover:bg-gray-500 hover:text-white'
                ], "group ml-4 mt-4 h-11 w-11 shrink-0 cursor-pointer border border-current p-2.5 transition-colors"])
        }, [
          _createVNode(_component_biz_icon, {
            name: icon,
            class: "h-full w-full fill-current transition-colors"
          }, null, 8, ["name"])
        ], 10, _hoisted_5))
      }), 128)),
      _createElementVNode("div", {
        title: "copy",
        id: "tr-share-copy",
        class: _normalizeClass([[
                    _ctx.contrast
                        ? 'text-white hover:bg-white hover:text-gray-500'
                        : 'text-gray-500 hover:bg-gray-500 hover:text-white'
                ], "group ml-4 mt-4 h-11 w-11 shrink-0 cursor-pointer border border-current p-2.5 transition-colors"]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)))
      }, [
        _createVNode(_component_biz_icon, {
          name: "copy",
          class: "h-full w-full fill-current transition-colors"
        })
      ], 2),
      _createElementVNode("div", {
        title: "print",
        id: "tr-share-print",
        class: _normalizeClass([[
                    _ctx.contrast
                        ? 'text-white hover:bg-white hover:text-gray-500'
                        : 'text-gray-500 hover:bg-gray-500 hover:text-white'
                ], "group ml-4 mt-4 h-11 w-11 shrink-0 cursor-pointer border border-current p-2.5 transition-colors"]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.print && _ctx.print(...args)))
      }, [
        _createVNode(_component_biz_icon, {
          name: "print",
          class: "h-full w-full fill-current transition-colors"
        })
      ], 2)
    ])
  ]))
}