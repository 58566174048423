
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import { filterItems } from '@/utils';
import BizIcon from '@/components/base/BizIcon.vue';
import DropdownItem from '@/components/base/DropdownItem.vue';

export default defineComponent({
    components: { BizIcon, CollapseTransition },
    props: {
        contrast: {
            type: Boolean,
            default: false
        },
        contrastMobile: {
            type: Boolean,
            default: false
        },
        redBorder: {
            type: Boolean,
            default: false
        },
        hiddenTitle: {
            type: Boolean,
            default: false
        },
        title: String
    },
    data() {
        return {
            open: false,
            activeTitle: '',
            activeValue: '',
            items: []
        };
    },
    provide() {
        return {
            registerDropdownItem: this.registerItem,
            dropdownItemClicked: this.onItemClicked
        };
    },
    mounted() {
        document.addEventListener('click', this.onOutsideClick);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.onOutsideClick);
    },
    methods: {
        registerItem(item: InstanceType<typeof DropdownItem>) {
            item.contrast = this.contrast;
            this.items.push(item);
            if (
                this.$slots.default()[0].children.filter(filterItems).length === this.items.length
            ) {
                if (this.activeValue !== '') {
                    if (this.items.length > 0) {
                        this.setValue(this.activeValue);
                    } else {
                        this.activeValue = '';
                    }
                }
            }
        },
        onItemClicked(item: InstanceType<typeof DropdownItem>, fake = false) {
            this.items.forEach(i => {
                i.selected = false;
            });
            if (this.activeTitle === item.title && this.activeValue === item.value) {
                this.activeTitle = '';
                this.activeValue = '';
            } else {
                this.activeTitle = item.title;
                this.activeValue = item.value;
                item.selected = true;
            }
            this.open = false;
            this.$emit('select', { value: item.value, index: this.items.indexOf(item), fake });
        },
        onOutsideClick(evt) {
            let targetElement = evt.target;

            do {
                if (targetElement === this.$el) {
                    return;
                }
                targetElement = targetElement.parentNode;
            } while (targetElement);
            this.open = false;
        },
        setValue(value) {
            if (this.items.length > 0) {
                const item = this.items.find(item => item.value === value);
                if (item) {
                    this.onItemClicked(item, true);
                } else {
                    this.activeValue = '';
                    this.activeTitle = '';
                }
            } else {
                this.activeValue = value;
            }
        }
    },
    computed: {
        disabled(): boolean {
            return this.items.every(item => item.disabled);
        }
    }
});
