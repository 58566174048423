export default {
    mounted(element: HTMLElement, blinding) {
        // put main press html in content
        let content = document.getElementById('main-press').innerHTML;

        // put content html in content
        const contentSection = document.getElementsByClassName('tr-content-section');
        for (let i = 0; i < contentSection.length; i++) {
            content = content + contentSection[i].innerHTML;
        }

        // create div for copied information
        const copyElement = document.createElement('div');
        copyElement.innerHTML = blinding.value ?? 'Copied';
        copyElement.classList.add(
            '-bottom-16',
            'px-7',
            'py-3.5',
            'absolute',
            'bg-gray-200',
            'border-l-2',
            'border-green',
            'mt-3',
            'text-gray-400'
        );

        // create event listener
        element.addEventListener('click', () => {
            // convert to clipboard reach text
            const blobInput = new Blob([content], { type: 'text/html' });
            const clipboardItemInput = new ClipboardItem({ 'text/html': blobInput });
            navigator.clipboard.write([clipboardItemInput]);

            // add and remove indicator div that copied
            element.appendChild(copyElement);
            setTimeout(() => {
                element.removeChild(element.lastChild);
            }, 3000);
        });
    }
};
