import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "font-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.editMode)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", {
          ref: "content",
          style: _normalizeStyle(_ctx.height),
          class: "overflow-hidden transition-all duration-500"
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 4),
        (_ctx.exceedsHeight)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: _normalizeStyle(_ctx.gradHeight),
              class: _normalizeClass([[
                _ctx.collapsed ? '-mt-10 bg-gradient-to-t pt-8' : 'bg-transparent',
                _ctx.contrast ? 'from-gray-500 via-gray-500/90' : 'from-white via-white/90'
            ], "relative z-10 flex items-end transition-all duration-500"])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["mr-5 mb-3 h-px flex-1", _ctx.contrast ? 'bg-white' : 'bg-gray-500'])
              }, null, 2),
              _createElementVNode("div", {
                class: _normalizeClass(["cursor-pointer font-bold", _ctx.contrast ? 'text-white' : 'text-gray-500']),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCollapsed && _ctx.toggleCollapsed(...args)))
              }, [
                _createTextVNode(_toDisplayString(_ctx.collapsed ? _ctx.showMoreLabel : _ctx.showLessLabel) + " ", 1),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.collapsed ? '+' : '–'), 1)
              ], 2)
            ], 6))
          : _createCommentVNode("", true)
      ]))
}